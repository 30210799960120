import React from "react";

import PageHelmet from "components/PageHelmet";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";

const RecruitingPartnersPage = (): React.ReactElement => {
  const isFreeCustomer = useIsFreeCustomer();

  const embedUrl = isFreeCustomer
    ? "https://www.dover.com/app-embed/free-select-recruiter-6aeff4"
    : "https://www.dover.com/app-embed/paid-select-recruiter-b0fed6";

  return (
    <>
      <PageHelmet title="Dover Recruiting Partners" />
      <iframe
        src={embedUrl}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="Recruiting Partners"
      />
    </>
  );
};

export default RecruitingPartnersPage;
