import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { Editor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { throttle } from "lodash";
import { useEffect } from "react";

import { ModEnterHandler } from "components/library/TipTap/extensions/modEnter";
import { OneLiner } from "components/library/TipTap/oneLiner";
import { CommonEditorProps } from "components/library/TipTap/types";

export function useInitialEditorSetup({
  initialContent,
  onContentChange,
  placeholder,
  readOnly,
  customExtensions,
  onModEnter,
  skipSetContent,
  scrubContent,
}: CommonEditorProps): Editor | null {
  const onContentChangeWrapper = function(editor: Editor): void {
    const content = editor.getHTML();

    // no common use case for non-breaking spaces, but they happen a lot from clients copy pasting so replace them with regular spaces
    // regex replace is the faster replace option if there are many instances vs replaceAll("&nbsp;", " ");
    const scrubbedContent = scrubContent ? content?.replace(/&nbsp;/g, " ") : content;
    const madeChanges = scrubbedContent !== content;

    if (madeChanges) {
      // get cursor/selection location before updating content
      const { from } = editor.state.selection;
      editor.commands.setContent(scrubbedContent, false /* emitUpdate */);
      //reset the selection / cursor location afetr updating content
      editor.commands.focus(from);
    }
    onContentChange?.(content);
  };

  // Read only editors don't need an update function
  const throttledOnContentChange = !readOnly && onContentChange ? throttle(onContentChangeWrapper, 100) : undefined;

  // Instantiate the editor
  const editor = useEditor({
    extensions: [
      ModEnterHandler.configure({
        callback: onModEnter,
      }),
      StarterKit,
      Link,
      Underline,
      Placeholder.configure({
        // Use a placeholder:
        placeholder: placeholder,
        emptyEditorClass: "is-editor-empty",
      }),
      ...(customExtensions ?? []),
    ],
    content: initialContent,
    editable: !readOnly,
    onUpdate: ({ editor }) => {
      // Call the throttled function instead of the original function
      throttledOnContentChange?.(editor as Editor);
    },
  });

  // Clean up the editor on unmount so it doesn't stick around and cause issues with newly created editors
  // Issues that can be caused include incorrect refs held by the parents
  useEffect(() => {
    return (): void => {
      editor?.destroy();
    };
  }, [editor]);

  // Toggle between editable and read only
  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setEditable(!readOnly, false /* emitUpdate */);
  }, [editor, readOnly]);

  // If the content prop changes, update the editor
  useEffect(() => {
    if (editor !== null && initialContent !== undefined) {
      if (skipSetContent && editor.getHTML() === initialContent) {
        return;
      }

      editor.commands.setContent(
        initialContent,
        false /* emitUpdate */,
        { preserveWhitespace: true } /* parseOptoins */
      );
    }
  }, [initialContent, editor, skipSetContent]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setOptions({
      editable: !readOnly,
    });
  }, [editor, readOnly]);

  if (initialContent === undefined) {
    return null;
  }

  return editor;
}

export function useSingleLineInitialEditorSetup({
  initialContent,
  onContentChange,
  placeholder,
  readOnly,
  customExtensions,
  onModEnter,
  skipSetContent,
}: CommonEditorProps): Editor | null {
  // Read only editors don't need an update function
  const throttledOnContentChange = onContentChange ? throttle(onContentChange, 100) : undefined;

  // Instantiate the editor
  const editor = useEditor({
    extensions: [
      OneLiner,
      Text,
      Paragraph,
      ModEnterHandler.configure({
        callback: onModEnter,
      }),
      Placeholder.configure({
        // Use a placeholder:
        placeholder: placeholder,
        emptyEditorClass: "is-editor-empty",
      }),
      ...(customExtensions ?? []),
    ],
    content: initialContent,
    editable: !readOnly,
    onUpdate: ({ editor }) => {
      // Call the throttled function instead of the original function
      throttledOnContentChange?.(editor.getHTML());
    },
  });

  // Clean up the editor on unmount so it doesn't stick around and cause issues with newly created editors
  // Issues that can be caused include incorrect refs held by the parents
  useEffect(() => {
    return (): void => {
      editor?.destroy();
    };
  }, [editor]);

  // If the initialContent prop changes, update the editor
  useEffect(() => {
    if (editor !== null && initialContent !== undefined) {
      if (skipSetContent && editor.getHTML() === initialContent) {
        return;
      }

      editor.commands.setContent(
        initialContent,
        false /* emitUpdate */,
        { preserveWhitespace: true } /* parseOptoins */
      );
    }
  }, [initialContent, editor, skipSetContent]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setOptions({
      editable: !readOnly,
    });
  }, [editor, readOnly]);

  if (initialContent === undefined) {
    return null;
  }

  return editor;
}
/**
 * This hook is used to update the variables in the variable highlighter extension for a given Editor instance
 */
export function useOnVariablesUpdate(editor: Editor | null, variables?: string[]): void {
  useEffect(() => {
    if (!editor || !variables || variables.length === 0) {
      return;
    }

    // If we haven't registered the variable highlighter extension, no-op
    // Otherwise the updateVariables command will crash the page
    if (editor.extensionManager.extensions.find(extension => extension.name === "variableHighlighter") === undefined) {
      return;
    }

    editor.commands.updateVariables(variables);
  }, [editor, variables]);
}
