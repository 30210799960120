import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { HELP_CENTER_URL } from "App/appConstants";
import { INVITE_TEAM_QUERY_PARAM } from "App/components/Nav/constants";
import InviteNewUsersModal from "App/components/Nav/InviteNewUsersModal";
import { SideNavRecruitingPartners } from "App/components/Nav/SideNavRecruitingPartners";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { ReactComponent as UserPlusIcon } from "assets/icons/user-plus.svg";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

export const SideNavHelp: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const [inviteNewUsersModalOpen, setInviteNewUsersModalOpen] = useQueryParam(INVITE_TEAM_QUERY_PARAM, BooleanParam);

  const openInviteNewUsersModal = (): void => {
    setInviteNewUsersModalOpen(true);
  };

  const closeInviteNewUsersModal = (): void => {
    setInviteNewUsersModalOpen(false);
  };

  const handleInviteTeammatesClick = (): void => {
    navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.MEMBERS));
    openInviteNewUsersModal();
  };

  return (
    <>
      <InviteNewUsersModal open={!!inviteNewUsersModalOpen} onClose={closeInviteNewUsersModal} />
      <Stack
        justifyContent="space-evenly"
        spacing={0.25}
        width="100%"
        sx={{
          "& > button": {
            width: "100% !important",
            textAlign: "start",
          },
        }}
      >
        <SideNavRecruitingPartners />
        <Button
          variant="ghost"
          p={0.5}
          icon={{ Icon: HelpIcon, color: "transparent" }}
          onPress={(): void => {
            window.open(HELP_CENTER_URL, "_blank", "noopener noreferrer");
          }}
        >
          <BodySmall color={colors.grayscale.gray600}>Help Center</BodySmall>
        </Button>
        <Button variant="ghost" p={0.5} icon={{ Icon: UserPlusIcon }} onPress={handleInviteTeammatesClick}>
          <BodySmall color={colors.grayscale.gray600}>Invite Your Team</BodySmall>
        </Button>
      </Stack>
    </>
  );
};
