import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";

import PageHelmet from "components/PageHelmet";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";

const RecruitingPartnersTabPage = (): React.ReactElement => {
  const isFreeCustomer = useIsFreeCustomer();
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  const embedUrl = isFreeCustomer
    ? "https://www.dover.com/app-embed/free-select-recruiter-6aeff4"
    : "https://www.dover.com/app-embed/paid-select-recruiter-b0fed6";

  return (
    <Stack maxWidth="1200px" margin="0 auto" height="calc(100vh - 130px)">
      <PageHelmet title={job?.title ? `${job.title} - Recruiting Partners` : "Recruiting Partners"} />
      <iframe
        src={embedUrl}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="Recruiting Partners"
      />
    </Stack>
  );
};

export default RecruitingPartnersTabPage;
