/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20073
 */
export interface InlineResponse20073 {
    /**
     * 
     * @type {object}
     * @memberof InlineResponse20073
     */
    counts: object;
}

export function InlineResponse20073FromJSON(json: any): InlineResponse20073 {
    return InlineResponse20073FromJSONTyped(json, false);
}

export function InlineResponse20073FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20073 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'counts': json['counts'],
    };
}

export function InlineResponse20073ToJSON(value?: InlineResponse20073 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'counts': value.counts,
    };
}


