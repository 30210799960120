import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as AllInOneSolution } from "assets/images/all-in-one-solution.svg";
import Quote from "assets/images/onboarding-quote.png";

const OnboardingGraphic = (): React.ReactElement => {
  return (
    <Stack spacing={8} alignItems="center" width="100%">
      <AllInOneSolution />
      <img src={Quote} style={{ width: "100%", maxWidth: "445px" }} />
    </Stack>
  );
};

export default OnboardingGraphic;
