import ZoomSetupRoom2 from "assets/images/zoom_setup_room_2.png";
import ZoomWaitingRoom from "assets/images/zoom_waiting_room.png";
import { InstructionListItem } from "components/instructions/types";
import { PronounSelectOptions } from "views/CompanySetup/components/InterviewPreferences/types";

const GOOGLE_MEET = "GOOGLE_MEET";
const PHONE = "PHONE";
const ZOOM = "ZOOM";
const WHEREBY = "WHEREBY";
const IN_PERSON = "IN_PERSON";
const USE_INTERVIEWER_PREFERENCE = "USE_INTERVIEWER_PREFERENCE";

export const MEETING_TYPES_WITH_LOCATION_REQUIRED = [ZOOM, WHEREBY, IN_PERSON];

export const MEETING_TYPES_OPTIONS: Readonly<{ value: string; label: string }[]> = [
  { value: GOOGLE_MEET, label: "Google Meet (recommended)" },
  { value: PHONE, label: "Phone Call (you will call them)" },
  { value: WHEREBY, label: "Whereby" },
  { value: ZOOM, label: "Zoom" },
  { value: IN_PERSON, label: "In Person" },
  { value: USE_INTERVIEWER_PREFERENCE, label: "Default to interviewer's preference" },
];

export const DEFAULT_MEETING_TYPE = MEETING_TYPES_OPTIONS[0];

// https://eventguide.com/topics/one_digit_day_abbreviations.html
export const DAYS_OF_WEEK: Readonly<{ letter: string; label: string }[]> = [
  { letter: "M", label: "Mon" },
  { letter: "T", label: "Tue" },
  { letter: "W", label: "Wed" },
  { letter: "R", label: "Thu" },
  { letter: "F", label: "Fri" },
  { letter: "S", label: "Sat" },
  { letter: "U", label: "Sun" },
];

export const setupZoomMeetingRoomInstructionItems: Readonly<InstructionListItem[]> = [
  {
    text: "Sign in to the Zoom web portal.",
  },
  {
    text: "In the navigation panel, click Settings.",
  },
  {
    text: "Click the Meeting tab.",
  },
  {
    text:
      'Click on the Security tab. We recommend that you enable the Waiting Room feature to prevent anyone from entering into an ongoing call. If you have the passcode feature enabled, make sure you toggle on "Embed passcode in invite link" as shown below.',
    imageUrl: ZoomWaitingRoom,
  },
  {
    text:
      "If the setting is disabled, click the toggle to enable it. If a verification dialog appears, click Turn On to verify the change.",
  },
  {
    text:
      "Note: If the option is grayed out, it has been locked at either the group or account level. You will need to contact your Zoom admin in order to make the necessary changes.",
  },
  {
    text: "Paste your new invite link here in your interviewing preferences.",
    imageUrl: ZoomSetupRoom2,
  },
];

export const articles = ["-", "a", "an", "the", "our"];

export const pronounsOptions: PronounSelectOptions[] = [`she/her/hers`, `he/him/his`, `they/them/theirs`];
