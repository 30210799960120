import { Box, Stack } from "@mui/material";
import React, { FC, ReactElement } from "react";

import { Body, BodyExtraSmall } from "components/library/typography";

interface SetupStepProps {
  step: number;
  description: string;
  helpText?: string;
  content?: ReactElement;
}

export const SetupStep: FC<SetupStepProps> = ({ step, description, helpText, content }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{
          display: "flex",
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "center",
          height: "24px",
          width: "24px",
          border: "1px solid black",
          borderRadius: 100,
        }}
      >
        {step}
      </Box>
      <Stack spacing={1}>
        <Stack spacing={0.5}>
          <Body>{description}</Body>
          {helpText && <BodyExtraSmall italic>{helpText}</BodyExtraSmall>}
        </Stack>
        {content}
      </Stack>
    </Stack>
  );
};
