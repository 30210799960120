import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { CompanySlugAtom, CompanyUrlInputAtom } from "components/dover/EditCareersPage/atoms";
import EditCareersPageForm from "components/dover/EditCareersPage/EditCareersPageForm";
import { EditCareersPageSchema, EditCareersPageSchemaFormType } from "components/dover/EditCareersPage/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { BlockNav } from "components/library/BlockNav";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useUpdateClientOnboardingMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { ClientOnboarding } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { careersPageSetupModalAtom } from "views/EditCareersPage/components/CareersPageSetupModal/CareersPageSetupModal";

export const CAREERS_PAGE_SCROLLABLE_CONTAINER_ID = "careers-page-scrollable-container";

interface EditCareersPageFormProps {
  isLoading: boolean;
  clientOnboarding: ClientOnboarding;
}

export const EditCareersPageFormWrapper = ({
  isLoading: isLoadingProp,
  clientOnboarding,
}: EditCareersPageFormProps): React.ReactElement => {
  const careersPageSetupEnabled = useFeatureFlag(FeatureFlag.CareersPageSetup);

  const { open } = useModal(careersPageSetupModalAtom);

  const companyUrlInputAtom = useAtomValue(CompanyUrlInputAtom);
  const setCompanySlugAtom = useSetAtom(CompanySlugAtom);

  // Load data
  const { data: client } = useGetUsersClientQuery();
  const clientId = client?.id;

  setCompanySlugAtom(clientOnboarding.slug ?? "");

  // Mutations
  const [
    updateClientOnboardingMutation,
    { isLoading: isCompanyOnboardingUpdating, isError: isClientOnboardingUpdateError },
  ] = useUpdateClientOnboardingMutation();

  // RHF setup
  const formMethods = useForm<EditCareersPageSchemaFormType>({
    resolver: zodResolver(EditCareersPageSchema),
    defaultValues: {
      image: clientOnboarding.careersPageImageUrl ?? undefined,
      companyInfo: clientOnboarding.careersPageInfo ?? undefined,
      logo: clientOnboarding.logoUrl ?? undefined,
      // If careersPageLogoRedirectUrl is not defined, use companyUrlInputAtom to use what was set in the import careers page step
      companyUrl: clientOnboarding.careersPageLogoRedirectUrl ?? companyUrlInputAtom ?? "",
      companySlug: clientOnboarding.slug ?? "",
    },
  });

  const { handleSubmit } = formMethods;

  // callbacks
  const onSave = (): void => {
    if (!clientId) {
      console.error("No client id found");
      return;
    }
    const { companyInfo, logo, image, companyUrl, companySlug } = formMethods.getValues();
    setCompanySlugAtom(companySlug);
    updateClientOnboardingMutation({
      id: clientId,
      data: {
        careersPageInfo: companyInfo,
        careersPageLogoRedirectUrl: companyUrl,
        slug: companySlug,
      },
      logoUrl: logo,
      careersPageImageUrl: typeof image !== "string" ? image : undefined,
      onboardingSection: OnboardingSection.CAREERS_PAGE,
    });
  };

  useEffect(() => {
    if (isClientOnboardingUpdateError) {
      showErrorToast("Error saving your company info. Please refresh and try again.");
    }
  }, [isClientOnboardingUpdateError]);

  return (
    <Stack spacing={1}>
      {careersPageSetupEnabled && (
        <Banner
          variant={client?.careersPagePosted ? BannerVariant.Gray : BannerVariant.Success}
          overrideIcon={<InfoIcon />}
          centeredIcon
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Add your Careers page to your website</BodySmall>
            <Button variant={ButtonVariant.SecondarySuccess} onClick={open}>
              Setup
            </Button>
          </Stack>
        </Banner>
      )}
      <FormProvider {...formMethods}>
        <BlockNav when={formMethods.formState.isDirty} />
        <Card>
          <Stack
            boxSizing="border-box"
            height="100%"
            maxHeight="calc(100vh - 202px)"
            width="100%"
            overflow="hidden"
            justifyContent="center"
            pb={5}
          >
            <Stack
              spacing={2}
              flexGrow={1}
              height="auto"
              py={2}
              px={3}
              overflow="auto"
              minHeight="0"
              className="hide-scrollbar"
              id={CAREERS_PAGE_SCROLLABLE_CONTAINER_ID}
            >
              <EditCareersPageForm inOnboardingFlow={false} />
            </Stack>
          </Stack>
          <Stack
            alignItems="flex-end"
            position="absolute"
            width="100%"
            left={0}
            bottom={0}
            px={2}
            py={1}
            sx={{ backgroundColor: colors.white, zIndex: 1 }}
            borderTop={`1px solid ${colors.grayscale.gray200}`}
            borderRadius={"0 0 6px 6px"}
          >
            <Button
              variant={ButtonVariant.Primary}
              // @ts-ignore
              onClick={handleSubmit(onSave)}
              loading={isCompanyOnboardingUpdating}
              disabled={isLoadingProp}
            >
              Save
            </Button>
          </Stack>
        </Card>
      </FormProvider>
    </Stack>
  );
};
