import "components/library/TipTap/TipTapEditorStyles.scss";

import { Stack, Box, SxProps, Theme } from "@mui/material";
import Image from "@tiptap/extension-image";
import { EditorContent } from "@tiptap/react";
import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { maxFileSizeUpload } from "components/constants";
import { useInitialEditorSetup } from "components/library/TipTap/hooks";
import { MenuBar } from "components/library/TipTap/MenuBar";
import { CommonEditorProps } from "components/library/TipTap/types";
import { useUploadClientImageMutation } from "services/doverapi/endpoints/client/endpoints";
import { colors } from "styles/theme";
import { showErrorToast, toastOptions } from "utils/showToast";

export interface EditorWithMenuProps extends CommonEditorProps {
  scrollableContainerId?: string;
  enableImages?: boolean;
}

const EditorWithMenu = ({
  initialContent,
  onContentChange,
  placeholder,
  readOnly,
  customExtensions,
  onModEnter,
  scrollableContainerId,
  enableImages,
  scrubContent,
}: EditorWithMenuProps): React.ReactElement => {
  const editor = useInitialEditorSetup({
    initialContent,
    onContentChange,
    placeholder,
    readOnly,
    customExtensions: [...(customExtensions ?? []), Image],
    onModEnter,
    scrubContent,
  });

  const menuBarRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const scrollableContainer = scrollableContainerId && document.getElementById(scrollableContainerId);
  const target = scrollableContainer || window;
  const topPosition = scrollableContainer ? scrollableContainer.getBoundingClientRect().top : 0;
  const { width: editorWidth } = editorRef.current?.getBoundingClientRect() ?? {
    top: 0,
    bottom: 0,
    width: 0,
  };

  const [isSticky, setIsSticky] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const stickyStyles: SxProps<Theme> = {
    borderBottom: `1px solid ${colors.grayscale.gray300}`,
    borderRadius: "4px",
  };
  if (isHidden) {
    stickyStyles["animation"] = "fadeOut 0.3s ease-in-out both";
    // @ts-ignore
    stickyStyles["@keyframes fadeOut"] = {
      from: { opacity: 1 },
      to: { opacity: 0 },
    };
  }

  const handleScroll = (): void => {
    const { height: menuBarHeight, bottom: menuBarBottom } = menuBarRef.current?.getBoundingClientRect() ?? {
      height: 0,
      bottom: 0,
    };
    const { top: editorTop, bottom: editorBottom } = editorRef.current?.getBoundingClientRect() ?? {
      top: 0,
      bottom: 0,
      width: 0,
    };
    setIsSticky((editorTop ?? topPosition + menuBarHeight + 1) <= topPosition + menuBarHeight);

    setIsHidden(editorBottom <= menuBarBottom + 200);
  };

  const [uploadClientImage] = useUploadClientImageMutation();

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    // Do something with the files
    if (acceptedFiles.length === 0 || !editor) {
      return;
    }

    const result = uploadClientImage({
      fileContents: acceptedFiles[0]!,
    }).unwrap();

    const image = await toast.promise(
      result,
      {
        pending: "Uploading image...",
        success: "Image uploaded!",
        error: "Error uploading image",
      },
      toastOptions
    );

    const url = image.fileContents;
    if (!url) {
      return;
    }

    editor
      .chain()
      .focus()
      .setImage({ src: url })
      .run();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: !enableImages,
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: [".png", ".jpg", ".jpeg"],
    maxFiles: 1,
    maxSize: maxFileSizeUpload,
    onDropRejected: () => showErrorToast("Invalid file type or file size"),
  });

  useEffect(() => {
    target.addEventListener("scroll", handleScroll);

    return (): void => {
      target.removeEventListener("scroll", handleScroll);
    };
  }, [target]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack width="100%" flexGrow={1} minHeight={0}>
      <div {...getRootProps()}>
        <MenuBar
          editor={editor}
          ref={menuBarRef}
          position={isSticky ? "fixed" : "relative"}
          top={isSticky ? topPosition : undefined}
          width={isSticky ? editorWidth : undefined}
          zIndex={isSticky ? 1000 : undefined}
          sx={isSticky ? stickyStyles : undefined}
          hideSaveTemplateButton
          imageUploadProps={getInputProps({ onClick: open })}
          openImageUpload={enableImages ? open : null}
        />
        <Box ref={editorRef}>
          <EditorContent editor={editor} className="EditorContainer" />
        </Box>
      </div>
    </Stack>
  );
};

export default EditorWithMenu;
