import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import usePossibleInterviewers, {
  NextInterviewerHiringPipelineStage,
} from "components/NextInterviewer/usePossibleInterviewers";
import { useListStageSpecificInterviewersQuery } from "services/doverapi/endpoints/candidate";
import { UseQueryResult } from "services/doverapi/types";
import { ClientInterviewer, ClientInterviewerSetupStateEnum } from "services/openapi";

export interface InterviewerOption {
  label: string;
  isDefaultInterviewer?: boolean;
  value?: string;
  hasSchedulingLinkOverride?: boolean;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  pictureUrl?: string;
  email?: string;
  createInterviewer?: boolean;
  isStageSpecific?: boolean;
  setupState?: ClientInterviewerSetupStateEnum;
  preferencesComplete?: boolean;
  isDoverInterviewer?: boolean;
}

export const useInterviewerOptions = ({
  desiredHiringPipelineStage,
  excludeDoverInterviewer,
  doverInterviewerIdOverride,
  excludeCreateNew,
  applySchedulingOrdering,
  excludeIncompletePreferences,
}: {
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
  excludeDoverInterviewer?: boolean;
  doverInterviewerIdOverride?: string;
  excludeCreateNew?: boolean;
  applySchedulingOrdering?: boolean;
  excludeIncompletePreferences?: boolean;
}): {
  options: UseQueryResult<InterviewerOption[]>;
} => {
  // Fetch the list of stage specific interviewers
  const {
    data: stageSpecificInterviewers,
    isFetching: stageSpecificInterviewersFetching,
    isError: isErrorStageSpecificInterviewers,
    isLoading: isLoadingStageSpecificInterviewers,
    isSuccess: isSuccessStageSpecificInterviewers,
  } = useListStageSpecificInterviewersQuery(
    desiredHiringPipelineStage?.id
      ? {
          hiringPipelineStageId: desiredHiringPipelineStage.id,
        }
      : skipToken
  );

  // Fetch the list of all possible interviewers
  const {
    possibleInterviewers,
    showDoverInterviewer,
    isFetchingInterviewers,
    doverInterviewerValue,
    isErrorInterviewers: isErrorPossibleInterviewers,
    isLoadingInterviewers: isLoadingPossibleInterviewers,
    isUninitializedInterviewers: isUninitializedPossibleInterviewers,
    isSuccessInterviewers: isSuccessPossibleInterviewers,
  } = usePossibleInterviewers({
    desiredHiringPipelineStage,
    includeDoverInterviewerOverride: false,
    doverInterviewerIdOverride,
  });

  // Create a map of stage specific interviewers for quick lookup
  const stageSpecificInterviewersObject = useMemo(() => {
    const stageSpecificInterviewersObject: {
      [key: string]: ClientInterviewer;
    } = {};

    if (stageSpecificInterviewers) {
      stageSpecificInterviewers.forEach(interviewer => {
        stageSpecificInterviewersObject[interviewer.id ?? ""] = interviewer;
      });
    }

    return stageSpecificInterviewersObject;
  }, [stageSpecificInterviewers]);

  // Create the list of options
  const options = useMemo(() => {
    if (isFetchingInterviewers || stageSpecificInterviewersFetching) {
      return [];
    }

    // Filter out interviewers that are already in the list of stage specific interviewers
    let interviewerOptions: InterviewerOption[] = possibleInterviewers
      .filter(interviewer => interviewer.id && !(interviewer.id in stageSpecificInterviewersObject))
      .map(interviewer => {
        return {
          value: interviewer.id,
          label: `${interviewer.fullName} <${interviewer.email}>`,
          hasSchedulingLinkOverride: interviewer.hasSchedulingLinkOverride,
          isDefaultInterviewer: false,
          fullName: interviewer.fullName,
          firstName: interviewer.firstName,
          lastName: interviewer.lastName,
          pictureUrl: interviewer.pictureUrl,
          email: interviewer.email,
          isStageSpecific: false,
          setupState: interviewer.setupState,
          // regular case + outlook users
          preferencesComplete:
            interviewer.setupState === ClientInterviewerSetupStateEnum.Ready ||
            (interviewer.setupState === ClientInterviewerSetupStateEnum.NeedsAuth &&
              !!interviewer.hasSchedulingLinkOverride),
        };
      });

    // Add back the stage specific interviewers to the list of options
    if (stageSpecificInterviewers) {
      interviewerOptions = [
        ...stageSpecificInterviewers.map(interviewer => {
          return {
            value: interviewer.id,
            label: `${interviewer.fullName} <${interviewer.email}>`,
            hasSchedulingLinkOverride: interviewer.hasSchedulingLinkOverride,
            isDefaultInterviewer: true,
            fullName: interviewer.fullName,
            firstName: interviewer.firstName,
            lastName: interviewer.lastName,
            pictureUrl: interviewer.pictureUrl,
            email: interviewer.email,
            isStageSpecific: true,
            setupState: interviewer.setupState,
            // regular case + outlook users
            preferencesComplete:
              interviewer.setupState === ClientInterviewerSetupStateEnum.Ready ||
              (interviewer.setupState === ClientInterviewerSetupStateEnum.NeedsAuth &&
                !!interviewer.hasSchedulingLinkOverride),
          };
        }),
        ...interviewerOptions,
      ];
    }

    // Add the dover interviewer to the list of options
    if (showDoverInterviewer && !excludeDoverInterviewer) {
      interviewerOptions.unshift({
        value: doverInterviewerValue.id,
        label: "Dover Interviewer",
        hasSchedulingLinkOverride: false,
        isDefaultInterviewer: false,
        fullName: "Dover Interviewer",
        firstName: "Dover",
        lastName: "Interviewer",
        email: "",
        isDoverInterviewer: true,
      });
    }

    // Add the option to add a new interviewer to the list of options
    if (!excludeCreateNew) {
      interviewerOptions.push({
        value: "createInterviewer",
        label: "Add a new interviewer +",
        hasSchedulingLinkOverride: false,
        isDefaultInterviewer: false,
        fullName: "",
        email: "",
        createInterviewer: true,
      });
    }

    // Apply the scheduling ordering
    if (applySchedulingOrdering) {
      // Dover Interviewer should always be first
      // Then interviews that are marked as default
      // Then interviews that are marked as stage specific
      interviewerOptions.sort((a, b) => {
        // Always put "Add a new interviewer +" at the end
        if (a.createInterviewer) return 1;
        if (b.createInterviewer) return -1;

        // Dover Interviewer should always be first
        if (a.isDoverInterviewer && !b.isDoverInterviewer) return -1;
        if (!a.isDoverInterviewer && b.isDoverInterviewer) return 1;

        // Then default interviewers
        if (a.isDefaultInterviewer && !b.isDefaultInterviewer) return -1;
        if (!a.isDefaultInterviewer && b.isDefaultInterviewer) return 1;

        // Then stage specific interviewers
        if (a.isStageSpecific && !b.isStageSpecific) return -1;
        if (!a.isStageSpecific && b.isStageSpecific) return 1;

        // Within the same category:
        // 1. Put blank fullNames at the bottom
        // 2. Sort alphabetically by fullName
        if (!a.fullName && b.fullName) return 1;
        if (a.fullName && !b.fullName) return -1;
        return (a.fullName || "").localeCompare(b.fullName || "");
      });
    }

    if (excludeIncompletePreferences) {
      interviewerOptions = interviewerOptions.filter(interviewer => interviewer.preferencesComplete);
    }

    return interviewerOptions;
  }, [
    isFetchingInterviewers,
    stageSpecificInterviewersFetching,
    possibleInterviewers,
    stageSpecificInterviewersObject,
    stageSpecificInterviewers,
    showDoverInterviewer,
    doverInterviewerValue,
    excludeDoverInterviewer,
    excludeCreateNew,
    applySchedulingOrdering,
    excludeIncompletePreferences,
  ]);

  return {
    options: {
      data: options,
      isLoading: isLoadingStageSpecificInterviewers || isLoadingPossibleInterviewers,
      isFetching: isFetchingInterviewers || stageSpecificInterviewersFetching,
      isError: isErrorPossibleInterviewers || isErrorStageSpecificInterviewers,
      isUninitialized: isUninitializedPossibleInterviewers,
      isSuccess: isSuccessPossibleInterviewers && isSuccessStageSpecificInterviewers,
    },
  };
};
