import { Button } from "@doverhq/dover-ui";
import { Box, Stack, Skeleton } from "@mui/material";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/chevron-right.svg";
import { BodyExtraSmall, BodySmall, PageTitle } from "components/library/typography";
import { useIsInViewport } from "hooks/useIsInViewport";
import { colors } from "styles/theme";
import { WhiteCard } from "views/job/JobSetup/steps/Overview/styles";
import { OverviewCardCount, OverviewCardType } from "views/job/JobSetup/steps/Overview/types";

interface OverviewCardProps {
  title: OverviewCardType;
  titleIcon?: ReactElement;
  cardCounts: OverviewCardCount[];
  loadingCounts?: boolean;
  footer?: ReactElement;
  useScrollButtons?: boolean;
}

const ScrollableContainer = styled(Stack)`
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SCROLL_INCREMENT = 175;

const OverviewCard = ({
  title,
  titleIcon,
  cardCounts,
  loadingCounts,
  footer,
  useScrollButtons,
}: OverviewCardProps): ReactElement => {
  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | null>(null);

  // When the scroll buttons are clicked, we perform a scroll operation on the scrollable content
  const scrollLeft = (): void =>
    scrollableRef?.scrollBy({
      left: -SCROLL_INCREMENT,
      top: 0,
      behavior: "smooth",
    });

  const scrollRight = (): void =>
    scrollableRef?.scrollBy({
      left: SCROLL_INCREMENT,
      top: 0,
      behavior: "smooth",
    });

  // Track the start and end of the tabs by ref so we can determine if they're in the viewport
  const tabsStartRef = React.useRef<HTMLAnchorElement>(null);
  const tabsEndRef = React.useRef<HTMLAnchorElement>(null);

  // When the start or end of the tabs is in the viewport, we'll hide the corresponding arrow
  const isTabStartInViewport = useIsInViewport(tabsStartRef);
  const isTabRightInViewport = useIsInViewport(tabsEndRef);

  // Track whether or not the scroll buttons should be visible
  const showLeftArrow = useMemo(() => !isTabStartInViewport, [isTabStartInViewport]);
  const showRightArrow = useMemo(() => !isTabRightInViewport, [isTabRightInViewport]);

  // When the scrollable content ref is mounted, we'll set it in state so we can perform operations on it
  const onScrollableRefMount = useCallback((node: HTMLDivElement): void => {
    setScrollableRef(node);
  }, []);

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack alignItems="center" spacing={1} direction="row" paddingBottom={1}>
        {!!titleIcon && titleIcon}
        <BodySmall color={colors.grayscale.gray700} weight="700">
          {title}
        </BodySmall>
      </Stack>
      <WhiteCard spacing={1} sx={{ flexGrow: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Box
            display={useScrollButtons ? "block" : "none"}
            visibility={showLeftArrow && useScrollButtons ? "visible" : "hidden"}
            width={showLeftArrow && useScrollButtons ? "auto" : "0"}
            border={`solid 1px ${colors.grayscale.gray300}`}
            borderRadius="100% !important"
          >
            <Button icon={{ Icon: ArrowLeft, color: "transparent" }} w={6} h={6} onPress={scrollLeft} />
          </Box>
          <ScrollableContainer ref={onScrollableRefMount}>
            {/* apologize for the random vw maxWidth here but is the easiest way to deal with 
            overview steps being out of the viewport/have to scroll to see it in the job overview page */}
            <Stack direction="row" maxWidth="40vw">
              {useScrollButtons && <Box ref={tabsStartRef} />}
              <Stack direction="row" spacing={3}>
                {loadingCounts && <CountSkeletons cardCount={cardCounts.length} />}
                {!loadingCounts &&
                  cardCounts?.map(({ label, count, labelIcon, link }: OverviewCardCount, idx) => (
                    <CountWithHeader key={idx} label={label} labelIcon={labelIcon} count={count} link={link} />
                  ))}
              </Stack>
              {useScrollButtons && <Box ref={tabsEndRef} marginLeft="-3px !important" />}
            </Stack>
          </ScrollableContainer>
          <Box
            display={useScrollButtons ? "block" : "none"}
            visibility={showRightArrow && useScrollButtons ? "visible" : "hidden"}
            width={showRightArrow && useScrollButtons ? "auto" : "0"}
            border={`solid 1px ${colors.grayscale.gray300}`}
            borderRadius="100% !important"
          >
            <Button icon={{ Icon: ArrowRight, color: "transparent" }} w={6} h={6} onPress={scrollRight} />
          </Box>
        </Stack>
        {!!footer && footer}
      </WhiteCard>
    </Stack>
  );
};

const CountSkeletons = ({ cardCount }: { cardCount: number }): ReactElement => {
  return (
    <>
      {Array(cardCount)
        .fill(null)
        .map(_ => (
          <Skeleton width="72px" height="40px" />
        ))}
    </>
  );
};

const CountWithHeader = ({
  label,
  labelIcon,
  count,
  link,
}: {
  label: string | undefined;
  labelIcon: ReactElement | undefined;
  count: number | string | undefined;
  link: string;
}): ReactElement => {
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="flex-start"
      onClick={(): void => navigate(link)}
      sx={{
        ":hover": {
          backgroundColor: colors.grayscale.gray100,
          cursor: "pointer",
        },
      }}
    >
      <PageTitle weight="500" color={count === 0 || count === "--" ? colors.grayscale.gray500 : colors.black}>
        {count}
      </PageTitle>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {!!labelIcon && labelIcon}
        <Box width="80px">
          <BodyExtraSmall
            weight="500"
            color={colors.grayscale.gray500}
            style={{
              maxHeight: "28px",
            }}
          >
            {label}
          </BodyExtraSmall>
        </Box>
      </Stack>
    </Stack>
  );
};

export default OverviewCard;
