/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientInterviewer
 */
export interface ClientInterviewer {
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    linkedinPublicId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    client: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    googleCredential?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    readonly setupState?: ClientInterviewerSetupStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ClientInterviewer
     */
    readonly hasSchedulingLinkOverride?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    schedulingLinkOverride?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientInterviewer
     */
    proUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientInterviewer
     */
    enableAiNotetakerForAllInterviews?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    aiNotetakerCustomFormatInstructions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterviewer
     */
    pictureUrl?: string;
}

/**
* @export
* @enum {string}
*/
export enum ClientInterviewerSetupStateEnum {
    Ready = 'READY',
    NeedsAuth = 'NEEDS_AUTH',
    NeedsSetup = 'NEEDS_SETUP'
}

export function ClientInterviewerFromJSON(json: any): ClientInterviewer {
    return ClientInterviewerFromJSONTyped(json, false);
}

export function ClientInterviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientInterviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'linkedinPublicId': !exists(json, 'linkedin_public_id') ? undefined : json['linkedin_public_id'],
        'client': json['client'],
        'googleCredential': !exists(json, 'google_credential') ? undefined : json['google_credential'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'hasSchedulingLinkOverride': !exists(json, 'has_scheduling_link_override') ? undefined : json['has_scheduling_link_override'],
        'schedulingLinkOverride': !exists(json, 'scheduling_link_override') ? undefined : json['scheduling_link_override'],
        'proUserId': !exists(json, 'pro_user_id') ? undefined : json['pro_user_id'],
        'enableAiNotetakerForAllInterviews': !exists(json, 'enable_ai_notetaker_for_all_interviews') ? undefined : json['enable_ai_notetaker_for_all_interviews'],
        'aiNotetakerCustomFormatInstructions': !exists(json, 'ai_notetaker_custom_format_instructions') ? undefined : json['ai_notetaker_custom_format_instructions'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
    };
}

export function ClientInterviewerToJSON(value?: ClientInterviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone_number': value.phoneNumber,
        'linkedin_public_id': value.linkedinPublicId,
        'client': value.client,
        'google_credential': value.googleCredential,
        'scheduling_link_override': value.schedulingLinkOverride,
        'pro_user_id': value.proUserId,
        'enable_ai_notetaker_for_all_interviews': value.enableAiNotetakerForAllInterviews,
        'ai_notetaker_custom_format_instructions': value.aiNotetakerCustomFormatInstructions,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'full_name': value.fullName,
        'picture_url': value.pictureUrl,
    };
}


