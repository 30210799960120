import { Skeleton } from "@mui/material";
import React, { ReactElement } from "react";

const OverviewCardSkeletonFooter = (): ReactElement => {
  return (
    <Skeleton
      sx={{
        padding: "8px",
        margin: "16px -8px -16px -8px !important",
      }}
    />
  );
};

export default OverviewCardSkeletonFooter;
