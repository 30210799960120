import { TextField, Menu, ClickAwayListener, Autocomplete, Stack } from "@mui/material";
import { orderBy } from "lodash";
import React, { ChangeEvent, useEffect, useState, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { CLIENT_ID_QUERY_PARAM, DOVER_CLIENT_INFO } from "App/appConstants";
import { reloadPage } from "App/components/ClientImpersonator/utils";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { adminDataReducerKey } from "domains/admin/reducer";
import { GlobalRootState } from "domains/global/types";
import { useDebounceState } from "hooks/useDebounceState";
import authConfig from "services/auth_config";
import {
  useGetUsersClientQuery,
  useAdminGetClientsV2Query,
  useSetClientAliasMutation,
} from "services/doverapi/endpoints/client/endpoints";
import { AdminClientV2 } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { reAuth } from "services/reAuth";
import { AppDispatch } from "store";
import { colors } from "styles/theme";

type ClientImpersonatorProps = {
  requiresSelectedClient?: boolean;
};

const ClientOption = (props: React.HTMLAttributes<HTMLLIElement> & { option: AdminClientV2 }): React.ReactElement => (
  <li {...props}>
    {props.option.name}
    {props.option.doverPlan ? ` (${props.option.doverPlan.toLowerCase()})` : ""}
  </li>
);

// Define these (or memoize them) outside the ClientImpersonator component:
const memoizedRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AdminClientV2): React.ReactNode => (
  <ClientOption {...props} option={option} />
);

const StyledButton = styled(Button)`
  padding: 6px;
  &:hover {
    background-color: ${colors.grayscale.gray200};
  }
`;

const ResetButton = styled(Button)`
  padding: 2px 8px;
  min-height: 24px;
  font-size: 12px;
  line-height: 1;
`;

const ClientImpersonator = ({ requiresSelectedClient }: ClientImpersonatorProps): React.ReactElement => {
  // Backend data mutations
  const [setClientAlias] = useSetClientAliasMutation();

  // Auth info
  const { user } = useAuth0();
  const { email: authedUsersEmail } = user;

  // Local state and query params
  const [aliasClient, setAliasClient] = React.useState<AdminClientV2 | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [clientIdParam, setClientIdParam] = useQueryParam(CLIENT_ID_QUERY_PARAM, StringParam);

  const [debouncedClientInput, setClientInput, clientInput] = useDebounceState<string | undefined>(undefined, 300);

  // Query data from backend
  const { data: clients, isLoading: clientsAreLoading, isFetching: clientsAreFetching } = useAdminGetClientsV2Query(
    debouncedClientInput
  );
  const { data: currentClient } = useGetUsersClientQuery();

  // Other helpers
  const dispatch = useDispatch<AppDispatch>();

  // Derived data
  const sortedClients = useMemo((): AdminClientV2[] | undefined => {
    if (clientsAreFetching) return undefined;
    return clients
      ? orderBy(
          clients,
          [
            (client: AdminClientV2): boolean => {
              return client.csm?.email !== authedUsersEmail;
            },
          ],
          ["asc"]
        )
      : undefined;
  }, [clients, clientsAreFetching, authedUsersEmail]);

  // Indicate to admin when clients are loading or not found
  const buttonText = useMemo((): string => {
    if (clientsAreLoading) return "Loading...";
    if (aliasClient) return aliasClient.name;
    if (currentClient) return currentClient.name;
    return "Dover";
  }, [aliasClient, clientsAreLoading, currentClient]);

  // Callbacks
  const setClientAliasHelper = useCallback(
    (clientId: string) => {
      const trySetClientAlias = async (): Promise<void> => {
        const data = await setClientAlias(clientId).unwrap();
        if (data.success) {
          reAuth();
          reloadPage();
        }
      };

      trySetClientAlias();
    },
    [setClientAlias]
  );

  const handleChange = useCallback(
    (selectedClient: AdminClientV2 | null): void => {
      if (selectedClient && selectedClient.id !== aliasClient?.id) {
        setClientAliasHelper(selectedClient.id!);
        setClientIdParam(selectedClient.id);
      }
    },
    [aliasClient?.id, setClientAliasHelper, setClientIdParam]
  );

  const handleReset = useCallback((): void => {
    setClientAliasHelper("733c3162-cbbd-6558-9866-1d6b8561f8b9");
    setClientIdParam("733c3162-cbbd-6558-9866-1d6b8561f8b9");
  }, [setClientAliasHelper, setClientIdParam]);

  // Effects

  // Set the client alias when the user is loaded
  useEffect(() => {
    if (!sortedClients) {
      return;
    }

    const clientId = user[authConfig.clientInfoUrl];
    if (!clientId) {
      return;
    }
    const client = sortedClients.find((c: AdminClientV2): boolean => c.id === clientId);

    if (client) {
      setAliasClient(client);
    }
  }, [sortedClients, dispatch, user]);

  useEffect(() => {
    if (requiresSelectedClient === undefined) {
      return;
    }

    const clientId = user[authConfig.clientInfoUrl];
    if (requiresSelectedClient) {
      if (!clientId) {
        // Case when a client must be selected and one isn't yet – set to Dover by default.
        setClientAliasHelper(DOVER_CLIENT_INFO.id!);
      } else if (!clientIdParam) {
        setClientIdParam(clientId);
      }
    }
  }, [clientIdParam, requiresSelectedClient, setClientAliasHelper, setClientIdParam, user]);

  // Show "Loading..." if still fetching; otherwise show "No clients found"
  const noOptionsText = clientsAreFetching ? "Loading..." : "No clients found";

  const showResetButton = useMemo(() => {
    const currentId = user[authConfig.clientInfoUrl];
    return currentId && currentId !== "733c3162-cbbd-6558-9866-1d6b8561f8b9";
  }, [user]);

  if (requiresSelectedClient === false) {
    return <></>;
  }

  // The client impersonator will grow to fill all available space in its parent container
  // It automatically dismisses the popper when a click occurs outside of it
  return (
    <ClickAwayListener onClickAway={(): void => setAnchorEl(null)}>
      <div>
        <Stack direction="row" spacing={1} alignItems="center">
          {showResetButton && (
            <ResetButton variant={ButtonVariant.Secondary} onClick={handleReset}>
              Reset
            </ResetButton>
          )}
          <StyledButton
            variant={ButtonVariant.Ghost}
            removePadding
            onClick={(e: React.MouseEvent<HTMLElement>): void => setAnchorEl(anchorEl ? null : e.currentTarget)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <BodySmall>{buttonText}</BodySmall>
              <ChevronDownIcon className="svg-fill" color={colors.grayscale.gray700} width="20px" height="20px" />
            </Stack>
          </StyledButton>
        </Stack>
        <Menu
          variant="menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={(): void => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              marginTop: "4px",
              minWidth: "300px",
              maxHeight: "none",
              boxShadow: "none",
              padding: "8px",
            },
          }}
        >
          <Autocomplete
            sx={{
              width: "300px",
              "& .MuiInputBase-root": {
                backgroundColor: colors.white,
                border: `1px solid ${colors.grayscale.gray300}`,
              },
            }}
            options={sortedClients ?? []}
            noOptionsText={noOptionsText}
            renderOption={memoizedRenderOption}
            getOptionLabel={(option: AdminClientV2): string => {
              const planName = option.doverPlan ? ` (${option.doverPlan.toLowerCase()})` : "";
              return `${option.name}${planName}`;
            }}
            value={aliasClient}
            inputValue={clientInput}
            onInputChange={(event, newInputValue): void => {
              setClientInput(newInputValue);
              event?.stopPropagation();
            }}
            onChange={(event: ChangeEvent<{}>, newValue: AdminClientV2 | null): void => {
              handleChange(newValue);
              event?.stopPropagation();
            }}
            renderInput={(params): React.ReactNode => (
              <TextField
                {...params}
                label="Select Client"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
                sx={{ backgroundColor: colors.white }}
              />
            )}
          />
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: GlobalRootState): ClientImpersonatorProps => ({
  requiresSelectedClient: state[adminDataReducerKey]?.requiresSelectedClient,
});

export default connect<ClientImpersonatorProps, unknown, unknown, GlobalRootState>(mapStateToProps)(ClientImpersonator);
