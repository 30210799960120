import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import theme from "styled-theming";

import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { colors } from "styles/theme";

export interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  variant: ButtonVariant;
  width?: string;
  height?: string;
  removePadding?: boolean;
  removeOutline?: boolean;
  inlineContent?: boolean;
  type?: "button" | "submit" | "reset";
  borderRadius?: string;
  grow?: boolean;
  id?: string;
  tooltip?: string | React.ReactElement;
  padding?: string;
}

export enum ButtonVariant {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  SecondaryLight = "SECONDARY_LIGHT",
  SecondarySuccess = "SECONDAY_SUCCESS",
  Critical = "CRITICAL",
  SecondaryCritical = "SECONDARY_CRITICAL",
  Warning = "WARNING",
  Ghost = "GHOST",
  GhostCritical = "GHOST_CRITICAL",
  GhostPrimary = "GHOST_PRIMARY",
  GhostPrimary2 = "GHOST_PRIMARY_2",
  Link = "LINK",
  GreenLink = "GREEN_LINK",
}

const backgroundColor = theme.variants("mode", "variant", {
  [ButtonVariant.Primary]: { light: colors.primary.base },
  [ButtonVariant.Secondary]: { light: colors.white },
  [ButtonVariant.SecondaryLight]: { light: colors.white },
  [ButtonVariant.SecondarySuccess]: { light: colors.white },
  [ButtonVariant.SecondaryCritical]: { light: colors.white },
  [ButtonVariant.Warning]: { light: colors.warning.base },
  [ButtonVariant.Critical]: { light: colors.critical.base },
  [ButtonVariant.Ghost]: { light: "transparent" },
  [ButtonVariant.GhostCritical]: { light: "transparent" },
  [ButtonVariant.GhostPrimary]: { light: colors.white },
  [ButtonVariant.GhostPrimary2]: { light: colors.grayscale.gray100 },
  [ButtonVariant.Link]: { light: "transparent" },
  [ButtonVariant.GreenLink]: { light: "transparent" },
});

const textColor = theme.variants("mode", "variant", {
  [ButtonVariant.Primary]: { light: colors.white },
  [ButtonVariant.Secondary]: { light: colors.secondary.base },
  [ButtonVariant.SecondaryLight]: { light: colors.grayscale.gray500 },
  [ButtonVariant.SecondarySuccess]: { light: colors.primary.base },
  [ButtonVariant.SecondaryCritical]: { light: colors.critical.base },
  [ButtonVariant.Warning]: { light: colors.white },
  [ButtonVariant.Critical]: { light: colors.white },
  [ButtonVariant.Ghost]: { light: colors.grayscale.gray600 },
  [ButtonVariant.GhostCritical]: { light: colors.critical.base },
  [ButtonVariant.GhostPrimary]: { light: colors.grayscale.gray600 },
  [ButtonVariant.GhostPrimary2]: { light: colors.grayscale.gray600 },
  [ButtonVariant.Link]: { light: colors.link },
  [ButtonVariant.GreenLink]: { light: colors.primary.base },
});

const hoverColor = theme.variants("mode", "variant", {
  [ButtonVariant.Primary]: { light: colors.primary.dark },
  [ButtonVariant.Secondary]: { light: colors.grayscale.gray100 },
  [ButtonVariant.SecondaryLight]: { light: colors.grayscale.gray100 },
  [ButtonVariant.SecondarySuccess]: { light: colors.grayscale.gray100 },
  [ButtonVariant.SecondaryCritical]: { light: colors.grayscale.gray100 },
  [ButtonVariant.Warning]: { light: colors.warning.dark },
  [ButtonVariant.Critical]: { light: colors.critical.dark },
  [ButtonVariant.Ghost]: { light: colors.grayscale.gray100 },
  [ButtonVariant.GhostCritical]: { light: colors.grayscale.gray100 },
  [ButtonVariant.GhostPrimary]: { light: colors.grayscale.gray100 },
  [ButtonVariant.GhostPrimary2]: { light: colors.grayscale.gray100 },
  [ButtonVariant.Link]: { light: "transparent" },
  [ButtonVariant.GreenLink]: { light: "transparent" },
});

const border = theme.variants("mode", "variant", {
  [ButtonVariant.Primary]: { light: "none" },
  [ButtonVariant.Secondary]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.SecondaryLight]: { light: `1px solid ${colors.grayscale.gray200}` },
  [ButtonVariant.SecondarySuccess]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.SecondaryCritical]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Warning]: { light: "none" },
  [ButtonVariant.Critical]: { light: "none" },
  [ButtonVariant.Ghost]: { light: "none" },
  [ButtonVariant.GhostCritical]: { light: "none" },
  [ButtonVariant.GhostPrimary]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.GhostPrimary2]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Link]: { light: "none" },
  [ButtonVariant.GreenLink]: { light: "none" },
});

const disabledBorder = theme.variants("mode", "variant", {
  [ButtonVariant.Primary]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Secondary]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.SecondaryLight]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.SecondarySuccess]: { light: colors.grayscale.gray300 },
  [ButtonVariant.SecondaryCritical]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Warning]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Critical]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Ghost]: { light: "none" },
  [ButtonVariant.GhostCritical]: { light: "none" },
  [ButtonVariant.GhostPrimary]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.GhostPrimary2]: { light: `1px solid ${colors.grayscale.gray300}` },
  [ButtonVariant.Link]: { light: "none" },
  [ButtonVariant.GreenLink]: { light: "none" },
});

interface StyledButtonProps {
  $disabled: boolean;
  variant: ButtonVariant;
  $width?: string;
  $height?: string;
  $removePadding?: boolean;
  $removeOutline?: boolean;
  $inlineContent?: boolean;
  $grow?: boolean;
  $borderRadius?: string;
  $padding?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  box-sizing: border-box;
  padding: ${(props): string => props.$padding || (props.$removePadding ? "0px" : "10px 16px")};
  display: ${(props): string | undefined => (props.$inlineContent ? "inline-flex" : undefined)};
  color: ${textColor};
  font-size: 14px;
  background-color: ${backgroundColor};
  border: ${border};
  border-radius: ${({ $borderRadius }): string => $borderRadius ?? "4px"};
  width: ${(props): string => props.$width ?? "auto"};
  height: ${(props): string => props.$height ?? "auto"};
  flex-grow: ${(props): number | undefined => (props.$grow ? 1 : undefined)};
  box-shadow: ${(props): string =>
    props.variant === ButtonVariant.GhostPrimary ? "0 1px 2px 0 rgba(0, 0, 0, 0.05)" : "none"};

  &:hover {
    background-color: ${hoverColor};
  }

  &:disabled {
    background-color: ${(props): string | theme.VariantSet<"variant", ButtonVariant> =>
      props.$disabled ? colors.grayscale.gray200 : backgroundColor};
    color: ${(props): string | theme.VariantSet<"variant", ButtonVariant> =>
      props.$disabled ? colors.grayscale.gray400 : textColor};
    border: ${(props): string | theme.VariantSet<"variant", ButtonVariant> =>
      (props.$disabled ? disabledBorder : border) + " !important"};
    pointer-events: none;
  }

  &:focus {
    outline: ${(props): string => (props.$removeOutline ? "none" : `1px solid ${colors.informational.base}`)};
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border-radius: 4px;
`;

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  tooltip,
  loading = false,
  disabled = false,
  width = undefined,
  height = undefined,
  removePadding = false,
  removeOutline = false,
  inlineContent = false,
  grow = false,
  borderRadius = undefined,
  id = undefined,
  padding = undefined,
  ...props
}) => {
  const button = (
    <StyledButton
      disabled={loading || disabled}
      {...props}
      $disabled={disabled}
      $width={width}
      $height={height}
      $removePadding={removePadding}
      $removeOutline={removeOutline}
      $inlineContent={inlineContent}
      $grow={grow}
      $borderRadius={borderRadius}
      $padding={padding}
      id={id}
    >
      {/* Render children to maintain size of button even during loading state */}
      {children}
      {/* Loading overlay automatically sizes to cover children content */}
      {loading && (
        <StyledOverlay>
          <DoverLoadingSpinner spinnerSize="24px" />
        </StyledOverlay>
      )}
    </StyledButton>
  );

  if (!tooltip) {
    return button;
  }

  return (
    // need to set width so the tooltip isn't fullWidth
    <Box width={width}>
      <Tooltip variant={TooltipVariant.Dark} title={tooltip ?? ""}>
        <div>{button}</div>
      </Tooltip>
    </Box>
  );
};
