import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListHiringPipelineStagesV2Query } from "services/doverapi/endpoints/hiringPipelineStage";
import { useCounts, useInterviewingStages } from "views/candidates/hooks";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import { OverviewCardCount, OverviewCardType } from "views/job/JobSetup/steps/Overview/types";

const CandidatesOverview = (): ReactElement => {
  const jobId = useJobIdFromUrl();
  const { counts: candidateCounts, isFetching } = useCounts();
  const interviewStages = useInterviewingStages();
  const { isLoading: isLoadingInterviewStages } = useListHiringPipelineStagesV2Query(jobId ? { jobId } : skipToken);

  const isLoading = isFetching || isLoadingInterviewStages;

  if (!jobId) {
    return <></>;
  }

  const formattedInterviewStages = (interviewStages ?? []).filter(stage => stage.name !== "Offer");
  const offerStageAndCount = (interviewStages ?? []).filter(stage => stage.name === "Offer")[0];

  // Force offer stage to the end of the card
  const candidateCountsByInterviewStage: OverviewCardCount[] = [...formattedInterviewStages, offerStageAndCount].map(
    stage => {
      const hpsId = stage?.id;
      return {
        label: stage?.name,
        count: candidateCounts?.[stage?.name],
        link: APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId, new URLSearchParams({ hpsId: hpsId, page: "0" })),
      };
    }
  );

  return (
    <OverviewCard
      title={OverviewCardType.CANDIDATES}
      cardCounts={candidateCountsByInterviewStage}
      loadingCounts={isLoading}
      useScrollButtons
    />
  );
};

export default CandidatesOverview;
