import { Stack } from "@mui/system";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateCountsQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { CandidateFilterSourcingContextEnum, CandidateFilterStatusEnum } from "services/openapi";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import { OverviewCardType } from "views/job/JobSetup/steps/Overview/types";
import { ReferralStatusType } from "views/referralsV2/ReferrersTable";

const AgenciesOverview = (): ReactElement => {
  const jobId = useJobIdFromUrl();

  const { data: stats, isFetching: isFetchingStats } = useGetCandidateCountsQuery(
    !jobId
      ? skipToken
      : {
          args: {
            jobId,
            data: {
              countFilters: [
                {
                  name: ReferralStatusType.SUBMITTED,
                  filters: {
                    sourcingContext: [CandidateFilterSourcingContextEnum.Agency],
                  },
                },
                {
                  name: ReferralStatusType.ACTIVE,
                  filters: {
                    status: [CandidateFilterStatusEnum.Active],
                    sourcingContext: [CandidateFilterSourcingContextEnum.Agency],
                  },
                },
              ],
            },
          },
        }
  );

  const counts = jobId
    ? [
        {
          label: "Submitted",
          link: APP_ROUTE_PATHS.job.jobSetupStep(jobId, "agencies"),
          count: stats?.find(s => s.name === ReferralStatusType.SUBMITTED)?.count ?? "--",
        },
        {
          label: "Active",
          link: APP_ROUTE_PATHS.job.jobSetupStep(jobId, "agencies"),
          count: stats?.find(s => s.name === ReferralStatusType.ACTIVE)?.count ?? "--",
        },
      ]
    : [];

  return (
    <OverviewCard
      title={OverviewCardType.AGENCIES}
      cardCounts={counts}
      loadingCounts={isFetchingStats}
      footer={
        <Stack
          sx={{
            margin: "24px -16px -16px -16px !important",
            padding: "10px 16px !important",
          }}
        ></Stack>
      }
    />
  );
};

export default AgenciesOverview;
