import { Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import styled from "styled-components";

import { CompanySetupSectionType } from "App/routing/types";
import { FeedbackTemplateDrawerAtom, NewFeedbackTemplateButton } from "components/dover/FeedbackTemplates";
import { feedbackTemplateDrawerConfigAtom } from "components/dover/FeedbackTemplates/atoms";
import { FeedbackTemplateDrawerContextType } from "components/dover/FeedbackTemplates/types";
import { LearnMoreLink } from "components/library/LearnMoreLink";
import { BodySmall, Overline } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useListFeedbackTemplatesQuery } from "services/doverapi/endpoints/feedbackTemplates";
import { FeedbackTemplate } from "services/openapi";
import { colors } from "styles/theme";
import { H2 } from "styles/typography/headers";
import { feedbackTemplateNameFilterValAtom } from "views/CompanySetup/components/FeedbackTemplates/atoms";
import { FEEDBACK_TEMPLATES_PAGE_LIMIT } from "views/CompanySetup/components/FeedbackTemplates/constants";
import { FeedbackTemplatesSearchBar } from "views/CompanySetup/components/FeedbackTemplates/FeedbackTemplatesSearchBar";
import {
  AssignedJobsCell,
  CreatedByCell,
  NameCell,
  ActionsCell,
} from "views/CompanySetup/components/FeedbackTemplates/TemplateTableCells";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const GridWrapper = styled.div`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }
  .h-cell {
    background: ${colors.grayscale.gray100};
  }
  .MuiDataGrid-row {
    cursor: pointer;
  }
  /* Remove blue focus border from column headers */
  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }
  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  .MuiDataGrid-columnHeader--sorted {
    outline: none;
  }
  .MuiDataGrid-columnHeader {
    outline: none !important;
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
`;

export interface FeedbackTemplateTableParams extends Omit<GridRenderCellParams, "row"> {
  row: FeedbackTemplate & {
    modified?: string;
  };
}

const FeedbackTemplatesTableColumns: GridEnrichedColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: FeedbackTemplateTableParams): React.ReactElement => <NameCell {...params} />,
  },
  {
    field: "assignedStageCount",
    headerName: "Assigned Jobs",
    minWidth: 140,
    width: 140,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: FeedbackTemplateTableParams): React.ReactElement => <AssignedJobsCell {...params} />,
  },
  {
    field: "modified",
    headerName: "Last Modified",
    minWidth: 140,
    width: 140,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: FeedbackTemplateTableParams): React.ReactElement => {
      const modified = params.row.modified;
      if (!modified) return <BodySmall>-</BodySmall>;
      return <BodySmall>{format(new Date(modified), "MMM d, yyyy")}</BodySmall>;
    },
    valueGetter: (params): number | undefined => {
      return params.row.modified ? new Date(params.row.modified).getTime() : undefined;
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: FeedbackTemplateTableParams): React.ReactElement => <CreatedByCell {...params} />,
  },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 180,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: FeedbackTemplateTableParams): React.ReactElement => <ActionsCell {...params} />,
  },
];

const FIELD_TO_ORDERING_MAP: Record<string, string> = {
  name: "name",
  assignedStageCount: "assigned_stage_count",
  modified: "modified",
};

const FeedbackTemplatesTable = (): React.ReactElement => {
  const [tablePage, setTablePage] = React.useState(0);
  const clientId = useGetClientId();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const feedbackTemplateNameFilterVal = useAtomValue(feedbackTemplateNameFilterValAtom);

  // Convert MUI sort model to API ordering parameter
  const getOrdering = (model: GridSortModel): string | undefined => {
    if (!model.length) return undefined;

    // Only use first sort field since we only support single-field ordering
    const { field, sort } = model[0];
    const prefix = sort === "desc" ? "-" : "";

    return `${prefix}${FIELD_TO_ORDERING_MAP[field]}`;
  };

  const {
    currentData: { results: feedbackTemplates, count: templateCount } = {},
    isFetching: isFetchingTemplates,
  } = useListFeedbackTemplatesQuery(
    clientId
      ? {
          name: feedbackTemplateNameFilterVal,
          offset: tablePage * FEEDBACK_TEMPLATES_PAGE_LIMIT,
          limit: FEEDBACK_TEMPLATES_PAGE_LIMIT,
          ordering: getOrdering(sortModel),
        }
      : skipToken
  );

  // Jotai setters
  const setFeedbackTemplateDrawerConfig = useSetAtom(feedbackTemplateDrawerConfigAtom);
  const { open: openFeedbackTemplateDrawer } = useModal(FeedbackTemplateDrawerAtom);

  const onEdit = (feedbackTemplate: FeedbackTemplate): void => {
    setFeedbackTemplateDrawerConfig({
      existingTemplateId: feedbackTemplate.id,
      context: FeedbackTemplateDrawerContextType.Edit,
      assignedJobStageCount: feedbackTemplate.assignedStageCount,
    });
    openFeedbackTemplateDrawer({});
  };

  return (
    <GridWrapper>
      <DataGrid
        loading={isFetchingTemplates}
        autoHeight
        headerHeight={40}
        rowHeight={64}
        rows={feedbackTemplates ?? []}
        rowCount={templateCount}
        columns={FeedbackTemplatesTableColumns}
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        page={tablePage}
        pageSize={FEEDBACK_TEMPLATES_PAGE_LIMIT}
        onPageChange={setTablePage}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        onRowClick={(params: GridRowParams): void => {
          onEdit(params.row as FeedbackTemplate);
        }}
      />
    </GridWrapper>
  );
};

const FeedbackTemplates = (): React.ReactElement => {
  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.FEEDBACK_FORMS]}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack spacing={1}>
            <H2>Feedback Forms</H2>
            <LearnMoreLink
              description="Create custom scorecards and feedback forms that interviewers use to provide structured, standardized feedback on candidates after each interview."
              linkUrl="https://help.dover.com/en/articles/9341365-interview-feedback-forms#h_ae4f6a5e0c"
            />
          </Stack>
          <Stack direction="row" spacing={1} minWidth="260px">
            <FeedbackTemplatesSearchBar />
            <NewFeedbackTemplateButton />
          </Stack>
        </Stack>
        <FeedbackTemplatesTable />
      </Stack>
    </SectionWrapper>
  );
};

export default FeedbackTemplates;
