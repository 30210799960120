import { Progress } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC } from "react";

import DoverFooter from "assets/images/dover-footer.png";
import { CopyCodeSection } from "components/CopyCodeSection";
import { Overline } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { colors } from "styles/theme";
import { SetupStep } from "views/EditCareersPage/components/CareersPageSetupModal/SetupStep";

export const LinkSetup: FC = () => {
  const { data, isLoading } = useGetUsersClientQuery();

  let LinkCode;
  if (isLoading) {
    LinkCode = <Progress />;
  } else if (!data) {
    LinkCode = <Overline>Could not load client data</Overline>;
  } else {
    LinkCode = <CopyCodeSection text={`https://app.dover.com/jobs/${data.slug}`} />;
  }

  return (
    <Stack spacing={4}>
      <SetupStep step={1} description="Copy your unique link below." content={LinkCode} />
      <SetupStep
        step={2}
        description="Add the link to your website navigation."
        content={
          <img
            src={DoverFooter}
            alt="Dover Footer"
            style={{ width: "100%", border: `1px solid ${colors.grayscale.gray300}`, borderRadius: "6px" }}
          />
        }
      />
      <SetupStep step={3} description="Publish your changes and click the link to verify it works." />
    </Stack>
  );
};
