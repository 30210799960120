import { Box, CircularProgress, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";

import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { convertDoverUserToBasicEmailOption } from "components/dover/CustomizeNextSteps/components/utils";
import { SchedulableStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { useEmailSenderOptions } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/utils";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption, EmailEditorProps } from "components/library/TipTap/types";
import { Body } from "components/library/typography";
import { useGetSchedulingLinkQuery } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi";

type CandidateActionEmailEditorProps = Omit<
  EmailEditorProps,
  "variables" | "fromOptions" | "ccOptions" | "schedulingLinkConfig"
> & {
  candidateId: string;
  interviewer?: Interviewer;
  stage?: SchedulableStage;
  desiredHiringPipelineStageId?: string;
  duration?: number;
  hideSchedulingLink?: boolean;
  loading?: boolean;
  customerManagedScheduling?: boolean;
};

interface CandidateActionEmailEditorHandle {
  setEditorContent: (content: string) => void;
  handleSaveAsNewTemplate?: () => Promise<void>;
}

/**
 * Email editor that functions identically to the TipTap-based EmailEditor component, but with the addition of determining valid email sender options for you
 */
const CandidateActionEmailEditorRenderFunction: React.ForwardRefRenderFunction<
  CandidateActionEmailEditorHandle,
  CandidateActionEmailEditorProps
> = (
  {
    candidateId,
    interviewer,
    duration,
    desiredHiringPipelineStageId,
    stage,
    hideSchedulingLink,
    from,
    loading,
    customerManagedScheduling,
    ...rest
  },
  forwardedRef
): React.ReactElement => {
  const emailSenderOptions = useEmailSenderOptions({ candidateId });

  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  const ccOptions = useMemo<BasicEmailOption[] | undefined>(() => {
    if (!proUsers) {
      return undefined;
    }

    // Ensure that each option at least has an email
    const ccOptions = proUsers
      .filter(user => !!user.email)
      .map(convertDoverUserToBasicEmailOption)
      // Filter out users that were missing data and thus failed to convert
      .filter(user => !!user);

    return ccOptions as BasicEmailOption[];
  }, [proUsers]);

  const { data: schedulingLink } = useGetSchedulingLinkQuery(
    interviewer?.value
      ? {
          candidateId,
          interviewerId: interviewer.value,
          desiredHiringPipelineStageId,
          interviewDurationSeconds: duration,
        }
      : skipToken
  );

  // Loading state
  if (loading || emailSenderOptions === undefined) {
    return (
      <Box display="flex" height="100px" width="100%" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // Error state if email option is not valid
  if (!emailSenderOptions.find(option => option.id === from?.id)) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error: Invalid email sender. If problem persists please reach out to support.</Body>
      </Stack>
    );
  }

  const schedulingLinkConfig =
    !hideSchedulingLink && interviewer && schedulingLink?.link
      ? { schedulingLink: schedulingLink.link, label: interviewer.fullName }
      : undefined;

  return (
    <EmailEditor
      fromOptions={emailSenderOptions}
      from={from}
      ref={forwardedRef}
      ccOptions={ccOptions}
      schedulingLinkConfig={schedulingLinkConfig}
      improvedSchedulingLinkConfig={{
        candidateId,
        desiredHiringPipelineStageId,
        interviewDuration: duration,
        stage,
        customerManagedScheduling,
      }}
      {...rest}
    />
  );
};

export const CandidateActionEmailEditor = React.forwardRef(CandidateActionEmailEditorRenderFunction);
export type CandidateActionEmailEditorRefType = React.ElementRef<typeof CandidateActionEmailEditor>;
