import { AnchorTagNav, Scrollable } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useCallback, useState } from "react";

import PageHelmet from "components/PageHelmet";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { EnrichedCandidateSourceDetails } from "services/doverapi/endpoints/job-source-settings/types";
import { SCROLLABLE_CONTAINER_WIDTH } from "views/job/constants";
import { ConfigureSourceModal } from "views/job/JobBoards/components/ConfigureSource/ConfigureSourceModal";
import DiscoverCard from "views/job/JobBoards/components/DiscoverCard";
import PublishedCard from "views/job/JobBoards/components/PublishedCard";
import { PUBLISHED_SECTION_ID, DISCOVER_MORE_SECTION_ID } from "views/job/JobBoards/constants";

const idToHref = (id: string): string => `#${id}`;

const items = [
  {
    label: "Published",
    href: idToHref(PUBLISHED_SECTION_ID),
  },
  {
    label: "Discover More",
    href: idToHref(DISCOVER_MORE_SECTION_ID),
  },
];

const ScrollableContainerId = "scrollable-job-boards-page";

const JobBoardsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  const [isModalOpen, toggleModal] = useState(false);
  const [selectedEnrichedSource, setSelectedEnrichedSource] = useState<EnrichedCandidateSourceDetails | undefined>();

  const openModalWithSource = useCallback(
    (selectedSource: EnrichedCandidateSourceDetails): void => {
      setSelectedEnrichedSource(selectedSource);
      toggleModal(true);
    },
    [setSelectedEnrichedSource, toggleModal]
  );

  return (
    <Stack direction="row" width="100%" spacing={1} position="relative" height="calc(100vh - 120px)">
      <PageHelmet title={job?.title ? `${job.title} - Job Boards` : "Job Boards"} />
      <ConfigureSourceModal
        isModalOpen={isModalOpen}
        toggleModalOff={(): void => toggleModal(false)}
        selectedEnrichedSource={selectedEnrichedSource}
      />
      <Box position="sticky" top={0} minWidth="170px">
        <AnchorTagNav items={items} scrollableContainerId={ScrollableContainerId} />
      </Box>
      <Box width="100%" maxWidth={SCROLLABLE_CONTAINER_WIDTH}>
        <Scrollable id={ScrollableContainerId} height="100%">
          <Stack spacing={2}>
            <PublishedCard openModalWithSource={openModalWithSource} />
            <DiscoverCard openModalWithSource={openModalWithSource} />
          </Stack>
        </Scrollable>
      </Box>
    </Stack>
  );
};

export default JobBoardsPage;
