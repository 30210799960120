import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import { Box, Stack } from "@mui/material";
import React, { FC } from "react";

import { Body } from "components/library/typography";
import { colors } from "styles/theme";

interface HotkeyGlyphsProps {
  keys: string;
}

export const HotkeyGlyphs: FC<HotkeyGlyphsProps> = ({ keys }) => {
  // Hotkeys can be passed in as a comma separated string to indicate multiple hotkey options
  const keysArr = keys.split(",");

  return (
    <Stack direction="row" spacing={0.5}>
      {keysArr.map((k, i) => {
        // Hotkeys can be passed in as a plus separated string to indicate a key combination
        const keyComboArr = k.split("+");

        return (
          <Stack key={i} direction="row" spacing={0.5} alignItems="center">
            {keyComboArr.map((kc, j) => {
              return (
                <>
                  <Glyph label={kc} />
                  {j !== keyComboArr.length - 1 && <Body color={colors.white}>+</Body>}
                </>
              );
            })}
            {i !== keysArr.length - 1 && <Body color={colors.white}>or</Body>}
          </Stack>
        );
      })}
    </Stack>
  );
};

// This isn't exhaustive of what the hotkey library we use can accept
// If we need something more dynamic in the future we can probably leverage the same library
// Being used by useHotkey: https://github.com/ianstormtaylor/is-hotkey#readme
const GlyphReplacementMap = new Map([
  ["cmd", <KeyboardCommandKeyIcon fontSize="inherit" />],
  ["mod", <KeyboardCommandKeyIcon fontSize="inherit" />],
  ["opt", <KeyboardOptionKeyIcon fontSize="inherit" />],
  ["alt", <KeyboardOptionKeyIcon fontSize="inherit" />],
  ["ctrl", <KeyboardControlKeyIcon fontSize="inherit" />],
  ["left", <KeyboardArrowLeftIcon fontSize="inherit" />],
  ["right", <KeyboardArrowRightIcon fontSize="inherit" />],
  ["up", <KeyboardArrowUpIcon fontSize="inherit" />],
  ["down", <KeyboardArrowDownIcon fontSize="inherit" />],
  ["space", <SpaceBarIcon fontSize="inherit" />],
  ["enter", <KeyboardReturnIcon fontSize="inherit" />],
]);

interface GlyphProps {
  label: string;
}

const Glyph: FC<GlyphProps> = ({ label }) => {
  const glyph = GlyphReplacementMap.has(label) ? (
    GlyphReplacementMap.get(label)
  ) : (
    <Body color={colors.white}>{label}</Body>
  );

  return (
    // Putting the fontSize and color here because the MUI Icon typing seems to be broken when it comes to custom values
    <Box p={1} borderRadius={1} color={colors.white} fontSize="16px" bgcolor={colors.grayscale.gray700}>
      {glyph}
    </Box>
  );
};
