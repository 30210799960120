import { Stack } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LinkedInIcon from "assets/icons/linkedin-logo-placeholder.svg";
import { GmailAuthButton } from "components/dover/GmailAuth";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Body, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useUserManagedSchedulingConfig } from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import { useListPipelineCandidatesQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useDeactivateJobMutation } from "services/doverapi/endpoints/job";
import {
  CandidateFilterStatusEnum,
  HiringPipelineStageType,
  RespondedSubstageEnum,
  DeactivateJobDeactivationReasonEnum,
} from "services/openapi";
import { PointerOnHoverReactSVG, StyledDataGrid } from "views/job/JobSetup/steps/Overview/styles";
import { useRealHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";
import { ModalKeyType } from "views/job/types";

interface DeactivateConfirmationModalProps {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleOpenCompletionModal: () => void;
  modalKey: ModalKeyType;
  jobName: string | undefined;
  isSampleJob?: boolean;
}

const LearnMoreLink = (): React.ReactElement => (
  <BodySmall>
    <a
      href="https://help.dover.com/en/articles/6297904-how-can-i-deactivate-a-job-in-dover#h_b3fa70e69c"
      target="_blank"
      rel="noopener noreferrer"
    >
      {"(learn more)"}
    </a>
  </BodySmall>
);

export const DeactivateConfirmationModal = ({
  shouldShowModal,
  handleCloseModal,
  handleOpenCompletionModal,
  modalKey,
  jobName,
  isSampleJob = false,
}: DeactivateConfirmationModalProps): React.ReactElement => {
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully();

  // Get job from URL
  const { jobId } = useParams<{ jobId: string | undefined }>();

  const hasUserManagedScheduling = useUserManagedSchedulingConfig(jobId);
  // Local state
  const [selectedRows, setSelectedRows] = useState<Array<string | number>>([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "stage",
      sort: "asc" as GridSortDirection,
    },
  ]);

  // Fetch data
  const { stages: hiringPipelinesForJob, isLoading: hiringPipelinesLoading } = useRealHps();

  // at this point we want to build a list of pipelines within which we want to reject candidates
  // these are responded.interested and all interview stages
  const interviewHPSs = hiringPipelinesForJob?.filter(hps => hps.stageType === HiringPipelineStageType.INTERVIEW);
  const respondedInterviewHPS = hiringPipelinesForJob?.find(hps => hps.stageType === HiringPipelineStageType.RESPONDED);
  const interviewPipelineStages =
    interviewHPSs?.map(hps => {
      return {
        pipelineStageId: hps.id,
        pipelineSubstages: [],
      };
    }) ?? [];
  const clientReviewPipeline = respondedInterviewHPS
    ? [
        {
          pipelineStageId: respondedInterviewHPS.id!,
          pipelineSubstages: [RespondedSubstageEnum.INTERESTED],
        },
      ]
    : [];

  // want to show people from client review, and all interview stages
  const pipelinesToReject = [...clientReviewPipeline, ...interviewPipelineStages];

  const { data: candidatesResult, isFetching: isFetchingCandidates } = useListPipelineCandidatesQuery(
    jobId && !hiringPipelinesLoading
      ? {
          args: {
            jobId: jobId,
            data: {
              filters: {
                pipelineStages: pipelinesToReject,
                status: [CandidateFilterStatusEnum.Active],
                modified: { maxDaysAgo: 90 },
              },
            },
            limit: 1000,
          },
        }
      : skipToken
  );

  // since candidates loading depends on hiring pipeline stage loading, we should batch them together
  const awaitingCandidatesFetching = hiringPipelinesLoading || isFetchingCandidates;

  // Mutations
  const [deactivateJob, { isLoading: isDeactivatingJob }] = useDeactivateJobMutation();

  // Derived data
  const candidates = candidatesResult?.results ?? [];
  const { isLoading: clientLoading } = useGetUsersClientQuery();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams): string => params.row.contact.fullName ?? "-",
      renderCell: (params: GridRenderCellParams): React.ReactElement => (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ marginLeft: "5px" }}>
          <BodySmall onClick={(): void => handleNameClick(params.id as string)}>
            {params.row.contact.fullName ?? "-"}
          </BodySmall>
          <PointerOnHoverReactSVG
            src={LinkedInIcon}
            style={{ marginTop: "-3px" }}
            onClick={(): void => {
              window.open(
                `${
                  params.row.contact.socialLinks?.find((socialLink: any) => {
                    return socialLink.linkType === "LINKEDIN";
                  })?.url
                }`,
                "_blank",
                "noopener noreferrer"
              );
            }}
          />
        </Stack>
      ),
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 0.5,
      minWidth: 150,
      // order null indices at the end
      valueGetter: (params: GridValueGetterParams): string => params.row.candidatePipelineStage?.orderIndex ?? 10000,
      renderCell: (params: GridRenderCellParams): React.ReactElement => (
        <Stack sx={{ marginLeft: "5px" }}>
          <BodySmall>{params.row.candidatePipelineStage?.name || "-"}</BodySmall>
        </Stack>
      ),
    },
  ];

  const activeCandidateTableMaxHeight = 372;
  const activeCandidateRowHeight = 56;
  const activeCandidateTableHeight = activeCandidateRowHeight * (candidates?.length + 1) + 2;
  const showCandidateTable = candidates?.length > 0;

  const gmailNeedsAuth = hasUserManagedScheduling && !userAuthedSuccessfully && selectedRows.length > 0;

  const formatCandidatesToReject = useMemo(() => {
    return selectedRows.map((candidateId: string | number) => ({ id: candidateId as string }));
  }, [selectedRows]);

  // Callbacks
  const handleNameClick = useCallback((candidate_id: string): void => {
    window.open(`/candidates/${candidate_id}`, "_blank", "noopener noreferrer");
  }, []);

  const handleDeactivate = useCallback(() => {
    const deactivateJobAndOpenModal = async (): Promise<void> => {
      if (!jobId) {
        console.error("No job ID");
        return;
      }

      await deactivateJob({
        jobId: jobId,
        candidatesToReject: !selectedRows || isSampleJob ? undefined : formatCandidatesToReject,
        onlyCancelDiInterviews: true,
        deactivationReason: DeactivateJobDeactivationReasonEnum.DePrioritizedJobNoLongerHiring,
      });
      handleOpenCompletionModal();
    };

    deactivateJobAndOpenModal();
  }, [deactivateJob, formatCandidatesToReject, handleOpenCompletionModal, isSampleJob, jobId, selectedRows]);

  const SampleJobConfirmation = <Body>Are you sure you want to deactivate your Sample Job?</Body>;

  const ConfirmationBody = (
    <Stack spacing={3}>
      {!showCandidateTable && (
        <Stack direction="row" spacing={1}>
          <BodySmall>{"Are you sure you want to deactivate this job?"}</BodySmall>
          <LearnMoreLink />
        </Stack>
      )}
      {showCandidateTable && (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <BodySmall>{"Select which active candidates should receive a rejection email."}</BodySmall>
            <LearnMoreLink />
          </Stack>
          {awaitingCandidatesFetching ? (
            <DoverLoadingSpinner />
          ) : (
            <div style={{ height: activeCandidateTableHeight, maxHeight: activeCandidateTableMaxHeight }}>
              <StyledDataGrid
                loading={awaitingCandidatesFetching}
                rowHeight={activeCandidateRowHeight}
                rows={candidates ?? []}
                columns={columns}
                hideFooter
                checkboxSelection
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(model: GridSortModel): void => setSortModel(model)}
                onSelectionModelChange={(ids: Array<string | number>): void => {
                  setSelectedRows(ids);
                }}
              />
            </div>
          )}
          {gmailNeedsAuth && (
            <>
              <BodySmall weight="700">
                To send rejection notes to the selected candidates, please authenticate your gmail.
              </BodySmall>
              <GmailAuthButton />
            </>
          )}
        </Stack>
      )}
    </Stack>
  );

  return (
    <CustomModal
      open={shouldShowModal === modalKey}
      onClose={handleCloseModal}
      title={<Heading>{`Deactivate ${jobName ? jobName : "Job"}?`}</Heading>}
      maxWidth={"sm"}
      dialogActions={
        <Stack width="100%" direction="row" spacing={2} justifyContent="flex-end">
          <Button variant={ButtonVariant.Secondary} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.SecondaryCritical}
            loading={isDeactivatingJob}
            onClick={handleDeactivate}
            disabled={gmailNeedsAuth || clientLoading}
          >
            {selectedRows.length > 0 ? "Deactivate job and send rejection emails" : "Deactivate job"}
          </Button>
        </Stack>
      }
    >
      {isSampleJob ? SampleJobConfirmation : ConfirmationBody}
    </CustomModal>
  );
};
