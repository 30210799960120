/**
 *
 * Homepage
 *
 */

import { Stack } from "@mui/material";
import React, { StrictMode, useEffect } from "react";
import { useLocalStorage } from "react-use";
import styled, { keyframes } from "styled-components";

import Dots from "assets/images/dots.png";
import { Card } from "components/library/Card";
import { PageTitle } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useAuth0 } from "services/react-auth0-spa";
import { colors } from "styles/theme";
import { sortJobsByTitle } from "utils/job";
import { ActiveAndInactiveJobs } from "views/Homepage/components/ActiveAndInactiveJobs";
import ExpertCard from "views/Homepage/components/ExpertCard";
import { HomepageSetupGuide } from "views/Homepage/components/HomepageSetupGuide";
import { HomepageTabBar } from "views/Homepage/components/HomepageTabBar";
import { Background } from "views/Homepage/styles";
import { HomepageTabEnum } from "views/Homepage/types";

export const HOMEPAGE_TAB_LOCAL_STORAGE_KEY = "HomepageStoreLastUsedTab";

const Homepage = (): React.ReactElement => {
  const { user } = useAuth0();
  const isBasePlanCustomer = useIsBasePlanCustomer();

  const { data: userInfo, isLoading: userInfoIsLoading } = useGetAuthedUserInfoQuery();
  const {
    authedUsersActiveJobs,
    authedUsersInactiveJobs,
    notAuthedUsersActiveJobs,
    notAuthedUsersInactiveJobs,
    jobsLoading,
  } = useGetJobsDerivedData({ sortFunc: sortJobsByTitle });

  const [selectedTab, setSelectedTab] = useLocalStorage<HomepageTabEnum>(
    HOMEPAGE_TAB_LOCAL_STORAGE_KEY,
    HomepageTabEnum.MyJobs
  );

  const nonSampleJobId = authedUsersActiveJobs?.filter(job => !job.isSample)?.[0]?.id;
  const setupGuideJobId = nonSampleJobId || authedUsersActiveJobs?.[0]?.id;

  useEffect(() => {
    if (authedUsersActiveJobs && authedUsersActiveJobs.length === 0 && selectedTab === HomepageTabEnum.MyJobs) {
      setSelectedTab(HomepageTabEnum.OtherJobs);
    }
    // we should not be setting selectedTab as a dependency here, as it will run when the user selects a tab
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedUsersActiveJobs?.length]);

  const JobsList = React.useMemo((): React.ReactElement => {
    switch (selectedTab) {
      case HomepageTabEnum.MyJobs:
        return (
          <ActiveAndInactiveJobs
            activeJobs={authedUsersActiveJobs ?? []}
            activeJobsIsLoading={jobsLoading}
            inactiveJobs={authedUsersInactiveJobs ?? []}
            inactiveJobsIsLoading={jobsLoading}
          />
        );
      case HomepageTabEnum.OtherJobs:
        return (
          <ActiveAndInactiveJobs
            activeJobs={notAuthedUsersActiveJobs ?? []}
            activeJobsIsLoading={jobsLoading}
            inactiveJobs={notAuthedUsersInactiveJobs ?? []}
            inactiveJobsIsLoading={jobsLoading}
          />
        );
      default:
        return <></>;
    }
  }, [
    authedUsersActiveJobs,
    authedUsersInactiveJobs,
    jobsLoading,
    notAuthedUsersActiveJobs,
    notAuthedUsersInactiveJobs,
    selectedTab,
  ]);

  return (
    <>
      <PageHelmet title="Dover Home" />
      <Background backgroundImage={Dots}>
        <Stack p={2} mt={2} spacing={3} maxWidth="920px" margin="0 auto">
          <PageTitle color={colors.white}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <>Welcome, {user.given_name}</>
              <WaveAnimation>👋</WaveAnimation>
            </Stack>
          </PageTitle>

          {setupGuideJobId && !userInfoIsLoading && !userInfo?.setupGuideDismissed && (
            <Card>
              <StrictMode>
                <HomepageSetupGuide jobId={setupGuideJobId} />
              </StrictMode>
            </Card>
          )}
          {isBasePlanCustomer && userInfo?.setupGuideDismissed && <ExpertCard />}
          <Card>
            {selectedTab && (
              <HomepageTabBar
                selectedTab={selectedTab}
                myJobs={authedUsersActiveJobs}
                otherJobs={notAuthedUsersActiveJobs}
                setSelectedTab={setSelectedTab}
              />
            )}
            {JobsList}
          </Card>
        </Stack>
      </Background>
    </>
  );
};

const waveAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
10% {
  transform: rotate(12deg);
}
20% {
  transform: rotate(-8deg);
}
30% {
  transform: rotate(12deg);
}
40% {
  transform: rotate(-8deg);
}
60% {
  transform: rotate(0deg);
}
`;

const WaveAnimation = styled.div`
  margin-left: 6px;

  animation: ${waveAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
`;

export default Homepage;
