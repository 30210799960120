import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";

import { ReactComponent as ClipboardIconSVG } from "assets/icons/clipboard.svg";
import ComboButton, { ComboButtonVariant } from "components/library/combo-button/ComboButton";
import { LearnMoreLink } from "components/library/LearnMoreLink";
import { Subtitle1 } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useModal } from "GlobalOverlays/atoms";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { inviteReferrerModalAtom } from "views/referralsV2/InviteReferrerModal";
import { ReferrersTable } from "views/referralsV2/ReferrersTable";
import { copyReferralLink } from "views/referralsV2/utils";

/* -----------------------------------------------------------------------------
 * PrimaryView
 * -------------------------------------------------------------------------- */

const JobReferralsPage = (): React.ReactElement => {
  const clientId = useGetClientId();
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  const { open } = useModal(inviteReferrerModalAtom);

  return (
    <Stack maxWidth="800px" margin="0 auto" spacing={2}>
      <PageHelmet title={job?.title ? `${job.title} - Referrals` : "Referrals"} />
      {/* header */}
      <Stack paddingTop={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1}>
          <Subtitle1>Referrals</Subtitle1>
          <LearnMoreLink
            description="Invite referrers to submit candidates."
            linkUrl="https://help.dover.com/en/articles/10172682-employee-referrals"
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <ComboButton
            variant={ComboButtonVariant.Primary}
            onClick={(): void => {
              open({});
            }}
            label={"+ Invite"}
            overflowActions={[
              {
                icon: <ClipboardIconSVG className="svg-color" color={colors.brand} />,
                label: "Copy referral form link",
                onClick: (): void => {
                  copyReferralLink({
                    clientId,
                  });
                },
              },
            ]}
          />
        </Stack>
      </Stack>
      <ReferrersTable />
    </Stack>
  );
};

export default JobReferralsPage;
