import { Box, Modal, Pagination, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import JobsListFilter from "components/dover/JobsListFilter";
import PageHelmet from "components/PageHelmet";
import { useCandidateId } from "hooks/useCandidateId";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { CandidateFilterStatusEnum, PipelineCandidate } from "services/openapi";
import { screenSizesNumbers } from "styles/theme";
import CandidateDetailView from "views/CandidateDetail";
import { getReviewType } from "views/CandidateDetail/utils";
import { addPageToStageAtom } from "views/candidates/CandidateNavigation/atoms";
import ActionFilters from "views/candidates/CandidatesReview/components/ActionFilters";
import ReviewTypeSection from "views/candidates/CandidatesReview/components/ReviewTypeSection";
import NoCandidatesEmptyState from "views/candidates/components/NoCandidatesEmptyState";
import { CANDIDATES_LIMIT } from "views/candidates/constants";
import { useCandidates, useParams, useCandidateReviewStages } from "views/candidates/hooks";
import { QuickFilterEnum, ReviewFilterEnum } from "views/candidates/types";

const CandidatesReview = (): React.ReactElement => {
  const navigate = useNavigate();

  const jobId = useJobIdFromUrl();
  const candidateId = useCandidateId();
  const [{ quickFilter, page, status }, setParams] = useParams();
  const stages = useCandidateReviewStages();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tabletL));

  useEffect(() => {
    // If status is unset, set to Active only
    if (!status.length) {
      setParams({ status: [CandidateFilterStatusEnum.Active] });
    }
  }, [setParams, status.length]);

  useEffect(() => {
    setParams({ hpsId: stages?.map(s => s.id) });
  }, [setParams, stages]);

  useEffect(() => {
    if (quickFilter) {
      setParams({ quickFilter: quickFilter });
    } else {
      setParams({ quickFilter: QuickFilterEnum.AllNeedsAction });
    }
  }, [quickFilter, setParams]);

  const { data: candidatesData, isFetching } = useCandidates();
  const { results: candidates, count } = candidatesData || { results: [] as PipelineCandidate[], count: 0 };
  const addPageToStage = useSetAtom(addPageToStageAtom);

  useEffect(() => {
    const stageId = stages?.[0]?.id;
    if (candidatesData?.results && stageId) {
      addPageToStage({ stageId, page, candidates: candidatesData?.results });
    }
  }, [stages, page, addPageToStage, jobId, candidatesData]);

  const setPage = useCallback(
    (pageIndex: number) => {
      /* Account for 0-indexed page */
      setParams({ page: pageIndex - 1 });
    },
    [setParams]
  );

  const totalNumPages = useMemo(() => Math.ceil(count / CANDIDATES_LIMIT), [count]);

  const candidatesByReviewType: Record<ReviewFilterEnum, PipelineCandidate[]> = useMemo(() => {
    const initialReviewTypeMap: Record<ReviewFilterEnum, PipelineCandidate[]> = Object.keys(ReviewFilterEnum).reduce(
      (result, key) => {
        return { ...result, [ReviewFilterEnum[key as keyof typeof ReviewFilterEnum]]: [] };
      },
      {}
    ) as Record<ReviewFilterEnum, PipelineCandidate[]>;

    return candidates?.reduce((results, candidate) => {
      // Get candidates that need to be reviewed
      const reviewType = getReviewType(candidate);
      const mentionsSelected = quickFilter === QuickFilterEnum.Mentions;

      // Candidates that need to be reviewed can also have mentions attached to them
      // So we don't want to add these when the mentions filter is selected
      if (!mentionsSelected && reviewType) {
        results[reviewType].push(candidate);
      }
      // Get Mentions that need to be reviewed when the mentions tab is selected
      if (mentionsSelected && candidate.mentions && candidate.mentions.length > 0) {
        results[ReviewFilterEnum.Mentions].push(candidate);
      }

      return results;
    }, initialReviewTypeMap);
  }, [candidates, quickFilter]);

  useEffect(() => {
    /* Initialize to first page on initial page load */
    setParams({ page: 0 });
  }, [setParams]);

  return (
    <>
      <PageHelmet title="Dover Todos" />
      <Stack
        direction={smallScreen ? "column" : "row"}
        sx={{ padding: "24px", height: "100%" }}
        spacing={smallScreen ? 2 : 0}
      >
        <Stack
          sx={{
            paddingRight: "32px",
            flexBasis: smallScreen ? undefined : "160px",
            flexShrink: 0,
            flexGrow: 0,
            maxWidth: smallScreen ? undefined : "280px",
          }}
        >
          <JobsListFilter activeJobId={jobId ?? ""} />
        </Stack>
        <Stack
          sx={{
            flexBasis: smallScreen ? undefined : "564px",
            flexShrink: 0,
            flexGrow: 2,
            maxWidth: "1000px",
            overflow: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <ActionFilters />
          {!count && !isFetching && (
            <Stack alignItems={"center"} justifyContent={"center"} height="100%" width="100%">
              <NoCandidatesEmptyState jobId={jobId} variant="todo-list" />
            </Stack>
          )}
          <Box mt={3} mb={3}>
            {isFetching && <Skeleton width="100%" height="160px" />}
            {!isFetching && candidates && (
              <Stack spacing={2} marginBottom={3}>
                {Object.keys(candidatesByReviewType).map((reviewType, i) => {
                  const candidatesForReviewType = candidatesByReviewType[reviewType as ReviewFilterEnum];

                  if (!candidatesForReviewType.length) {
                    return;
                  }

                  return (
                    <ReviewTypeSection
                      reviewType={reviewType as ReviewFilterEnum}
                      candidates={candidatesForReviewType}
                      key={i}
                    />
                  );
                })}
                {/* Account for 0-indexed page */}
                <Pagination count={totalNumPages} page={page + 1} onChange={(e, value): void => setPage(value)} />
              </Stack>
            )}
          </Box>
        </Stack>
        <Modal open={Boolean(candidateId)} sx={{ height: "100%", zIndex: 1200 }}>
          <CandidateDetailView
            close={(): void => {
              navigate(APP_ROUTE_PATHS.review({ queryParams: new URLSearchParams(location.search) }));
            }}
          />
        </Modal>
      </Stack>
    </>
  );
};

export default CandidatesReview;
