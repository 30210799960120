import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateCountsQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { CandidateFilterSourcingContextEnum, CandidateFilterStatusEnum } from "services/openapi";
import { colors } from "styles/theme";
import { LightGrayLink } from "styles/typography";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import OverviewCardSkeletonFooter from "views/job/JobSetup/steps/Overview/components/OverviewCardSkeletonFooter";
import { OverviewCardType } from "views/job/JobSetup/steps/Overview/types";
import { ReferralStatusType } from "views/referralsV2/ReferrersTable";

const ReferralsOverview = (): ReactElement => {
  const jobId = useJobIdFromUrl();

  const { data: stats, isFetching: isFetchingStats } = useGetCandidateCountsQuery(
    !jobId
      ? skipToken
      : {
          args: {
            jobId,
            data: {
              countFilters: [
                {
                  name: ReferralStatusType.SUBMITTED,
                  filters: {
                    sourcingContext: [CandidateFilterSourcingContextEnum.Referral],
                  },
                },
                {
                  name: ReferralStatusType.ACTIVE,
                  filters: {
                    status: [CandidateFilterStatusEnum.Active],
                    sourcingContext: [CandidateFilterSourcingContextEnum.Referral],
                  },
                },
              ],
            },
          },
        }
  );

  const counts = jobId
    ? [
        {
          label: "Referrals",
          link: APP_ROUTE_PATHS.job.jobSetupStep(jobId, "referrals"),
          count: stats?.find(s => s.name === ReferralStatusType.SUBMITTED)?.count ?? "--",
        },
        {
          label: "Candidates",
          link: APP_ROUTE_PATHS.job.jobSetupStep(jobId, "referrals"),
          count: stats?.find(s => s.name === ReferralStatusType.ACTIVE)?.count ?? "--",
        },
      ]
    : [];

  return (
    <OverviewCard
      title={OverviewCardType.REFERRALS}
      cardCounts={counts}
      loadingCounts={isFetchingStats}
      footer={jobId && !isFetchingStats ? <ReferralsOverviewFooter jobId={jobId} /> : <OverviewCardSkeletonFooter />}
    />
  );
};

interface ReferralsOverviewFooterProps {
  jobId: string;
}

const ReferralsOverviewFooter = ({ jobId }: ReferralsOverviewFooterProps): ReactElement => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      borderTop={`solid 1px ${colors.grayscale.gray200}`}
      sx={{
        margin: "24px -16px -16px -16px !important",
        padding: "10px 16px !important",
      }}
    >
      <BodySmall>
        <LightGrayLink href={APP_ROUTE_PATHS.job.jobSetupStep(jobId, "referrals")}>Refer more</LightGrayLink>
      </BodySmall>
    </Stack>
  );
};

export default ReferralsOverview;
