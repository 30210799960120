import { Stack } from "@mui/material";
import React, { FC } from "react";

import { LearnMoreLink } from "components/library/LearnMoreLink";
import { H2 } from "styles/typography/headers";
import NewTemplateButton from "views/CompanySetup/components/EmailTemplates/components/NewTemplateButton";
import { CompanySetupSectionConfig } from "views/CompanySetup/constants";

interface SectionWrapperProps {
  config: CompanySetupSectionConfig;
}

const SectionWrapper: FC<React.PropsWithChildren<SectionWrapperProps>> = ({ children, config }) => {
  return (
    <Stack maxWidth={"1400px"} width={"100%"} flexGrow={1} spacing={2}>
      <Stack spacing={1}>
        <Stack width="100%" justifyContent="space-between" direction="row">
          {config.sectionLabel && <H2>{config.sectionLabel}</H2>}
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack width={config.sectionLabel === "Email Templates" ? "88%" : "100%"}>
            {config.description && <LearnMoreLink linkUrl={config.helpDeskArticle} description={config.description} />}
          </Stack>
          {config.sectionLabel === "Email Templates" && <NewTemplateButton style={{ marginLeft: "auto" }} />}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default SectionWrapper;
