import { useState } from "react";
import { useDispatch } from "react-redux";

import { useJobId } from "hooks/useJobIdFromUrl";
import { doverApi } from "services/doverapi/apiSlice";
import { candidateEndpoints } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useUpdateHiringPipelineStageV2Mutation } from "services/doverapi/endpoints/hiringPipelineStage";
import { CANDIDATE_BIO } from "services/doverapi/endpointTagsConstants";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUpdateHpsDefaultTemplate = (id: string, newTemplateId?: string | null) => {
  const [jobId] = useJobId();

  const [updateHiringPipelineStage] = useUpdateHiringPipelineStageV2Mutation();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return () => {
    if (!jobId) {
      return;
    }

    return updateHiringPipelineStage({
      id,
      jobId,
      data: {
        schedulingEmailTemplateId: newTemplateId,
      },
    });
  };
};

interface UseSetDefaultStageTemplateProps {
  stageId: string;
  newTemplateId?: string | null;
  candidateId?: string;
  invalidateCandidateBio?: boolean;
  optimisticUpdateCandidateBio?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSetDefaultStageTemplate = ({
  stageId,
  newTemplateId,
  candidateId,
  invalidateCandidateBio,
  optimisticUpdateCandidateBio,
}: UseSetDefaultStageTemplateProps) => {
  const [updatingDefaultTemplate, setUpdatingDefaultTemplate] = useState(false);
  const updateHps = useUpdateHpsDefaultTemplate(stageId, newTemplateId);
  const dispatch = useDispatch();

  const setDefaultStageTemplate = async (): Promise<void> => {
    setUpdatingDefaultTemplate(true);

    // Clear candidate BIO cache (the stage is part of the candidate BIO)
    if (invalidateCandidateBio) {
      dispatch(doverApi.util.invalidateTags([{ type: CANDIDATE_BIO, id: candidateId }]));
    }
    if (optimisticUpdateCandidateBio) {
      // perform an optimistic update of the candidate bio
      dispatch(
        candidateEndpoints.util.updateQueryData("getCandidateBio", candidateId!, draft => {
          const newStages = (draft?.jobStages ?? []).map((stage: any): any => {
            if (stage.id === stageId) {
              return {
                ...stage,
                schedulingEmailTemplateId: newTemplateId,
              };
            }
            return stage;
          });
          Object.assign(draft, {
            jobStages: newStages,
          });
        })
      );
    }

    await updateHps()?.unwrap();

    setUpdatingDefaultTemplate(false);
  };

  return {
    setDefaultStageTemplate,
    updatingDefaultTemplate,
  };
};
