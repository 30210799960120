import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useMemo } from "react";
import { useParams, Navigate } from "react-router-dom";

import { Body, PageTitle } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useGetCareersPageClientQuery } from "services/doverapi/endpoints/careersPageClient";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { CareersPageClientDoverPlanEnum } from "services/openapi";
import { generateRandomizedImageUrl } from "utils/image";
import { Header, HeaderContent, CompanyName, StyledLogo } from "views/inboundExternal/styles";

const AICompliancePage = (): ReactElement => {
  const { clientId: clientIdParam } = useParams<{ clientId: string }>();
  const userClientId = useGetClientId();

  // If the client id is not provided, use the user's client id. Allows for previewing the careers page.
  const clientId = clientIdParam ?? userClientId;

  const { client, isClientLoading } = useGetCareersPageClientQuery(clientId ? { clientId } : skipToken, {
    selectFromResult: ({ data, isLoading }) => {
      return {
        isClientLoading: isLoading,
        client: data,
      };
    },
  });

  const bodyText = useMemo(() => {
    return encodeURIComponent(
      "Hi — My name is [insert name]. I’d like to request a different interview process. The details of my request can be found below. \n\nRequest details:\nDo you live in NYC: [Yes/No]\n- Job interested: [insert job name]\n- Name: [insert name]\n- Email: [insert email]\n- Request: [insert request]\n- Linkedin Profile: [insert your linkedin profile link]\n- Resume: [please attach your resume]"
    );
  }, []);

  const subjectText = useMemo(() => {
    return encodeURIComponent(
      `${client?.name} - [insert job name] — Applicant requesting a different interview process`
    );
  }, [client]);

  const companyLogo = useMemo((): ReactElement => {
    return <StyledLogo src={generateRandomizedImageUrl(client?.logo)} alt=" url" />;
  }, [client?.logo]);

  const headerLogo = useMemo((): React.ReactElement => {
    return (
      <>
        {client?.careersPageLogoRedirectUrl ? (
          <a href={client?.careersPageLogoRedirectUrl}>{companyLogo}</a>
        ) : (
          companyLogo
        )}
      </>
    );
  }, [client?.careersPageLogoRedirectUrl, companyLogo]);

  if (isClientLoading || !client) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (client?.doverPlan === CareersPageClientDoverPlanEnum.NoAccess) {
    return <Navigate to="/NotFound" />;
  }

  return (
    <>
      <PageHelmet title={`${client.name} Careers`} />
      <Box
        style={{
          width: "100%",
          height: "fit-content",
          minHeight: "100vh",
        }}
      >
        <Header>
          <HeaderContent>
            {client && client.logo !== undefined ? (
              headerLogo
            ) : (
              <CompanyName>
                {client?.name}
                {" Careers"}
              </CompanyName>
            )}
          </HeaderContent>
        </Header>
        <Stack direction="row" justifyContent="center" p={2} pt={5}>
          <Box maxWidth={800}>
            <PageTitle style={{ fontSize: "32px" }}>{client.name} is an Equal Opportunity Employer</PageTitle>
            <br />
            <PageTitle>Our Commitment</PageTitle>
            <Body>
              {`Welcome to ${client?.name}, where equal opportunities power our dynamic, inclusive, and collaborative
              environment. We celebrate each individual's unique qualities, fueling growth and innovation while ensuring
              employment decisions are made without regard to race, color, religion, sex, gender identity, national
              origin, disability, veteran status, marital status, sexual orientation, or any other characteristic
              protected by law.`}
            </Body>
            <br />
            <Body>
              {
                "To ensure we provide the best recruiting experience, we leverage AI tools to streamline operations and ensure fairness. However, our hiring teams make the final decisions. To ensure that these tools meet our employment standards, we have audited these tools for bias and have shared our audits publicly. If you need accommodations throughout the application process, please don't hesitate to let us know."
              }
            </Body>
            <br />
            <Body>
              Ready to join the adventure? Discover our company values on our website and become part of our thriving
              community, where we passionately embrace diversity, promote inclusion, and make a bold, positive impact
              together!
            </Body>
            <br />
            <Body>
              Applicants have the right to request a different review process. Select the job that you would like to
              apply for and add your request to the form. If you don’t need a different review process, please apply to
              the role on the career page.
            </Body>
            <br />
            <PageTitle>Request a Different Review Process</PageTitle>
            <Body>
              Applicants based in New York City have the right to request a different review process,
              <a href={`mailto:customersupport@dover.com?subject=${subjectText}&body=${bodyText}`}> click here</a> to do
              so.
            </Body>
            <br />
            <PageTitle>Bias Audits on AI Tools</PageTitle>
            <Body>
              <a
                href={client?.biasAuditLink ?? "https://cdn.dover.io/compliance/Dover%20-%20Audit_Result.pdf"}
                target="_blank"
                rel="noreferrer"
              >
                Dover
              </a>
            </Body>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default AICompliancePage;
