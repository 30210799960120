import { Dialog, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC } from "react";

import { HotkeyGlyphs } from "App/hotkeys/HotkeyGlyphs";
import { listHotkeysAtom } from "App/hotkeys/useHotkey";
import { Body } from "components/library/typography";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";

export const HotkeyMenu: FC<React.PropsWithChildren<GlobalModalProps>> = ({ isOpen, close }) => {
  const hotkeys = useAtomValue(listHotkeysAtom);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth
      maxWidth="sm"
      sx={{ ".MuiDialog-paper": { backgroundColor: colors.grayscale.gray600 } }}
    >
      <DialogTitle color={colors.white}>Keyboard Shortcuts</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={1.5} p={2}>
          {hotkeys.map(h => {
            return (
              <Stack key={h[0]} direction="row" justifyContent="space-between" alignItems="center">
                <Body color={colors.white}>{h[1]}</Body>
                <HotkeyGlyphs keys={h[0]} />
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const HotkeyMenuAtom = modalAtom(HotkeyMenu);
