import { Collapse, Stack } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { useBoolean } from "react-use";

import {
  campaignMessageBodyAtom,
  campaignMessageCcEmailsAtom,
  campaignMessageSubjectAtom,
  campaignMessageValidAtom,
} from "components/dover/AddCandidate/atoms";
import { SCHEDULING_LINKS, VARIABLES } from "components/dover/AddCandidate/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { EmailEditorMode, EmailEditorModeAtom } from "components/library/TipTap/atoms";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption, EmailOwner } from "components/library/TipTap/types";
import { substituteVariables } from "components/library/TipTap/utils";
import { BodySmall } from "components/library/typography";
import { VariableSelector } from "components/VariableSelector";
import { useCcOptions } from "hooks/useCcOptions";
import { CampaignMessage } from "services/openapi";
import { colors } from "styles/theme";
import { getPlainTextFromHtml } from "utils/draftJS";

export const CAMPAIGN_VARIABLE_KEYS = Object.keys(VARIABLES);

const validate = (subject: string, body: string): any => {
  const errors: any = {};
  const matches = (subject ? [...Array.from(subject!.matchAll(/{{(.*?)}}/g))] : [])
    .concat(body ? [...Array.from(body!.matchAll(/{{(.*?)}}/g))] : [])
    .map(match => match[1])
    .filter(match => !CAMPAIGN_VARIABLE_KEYS.includes(match));
  if (matches.length > 0) {
    errors.customVariables = matches;
  }
  SCHEDULING_LINKS.forEach(link => {
    if (subject?.toLowerCase().includes(link) || body?.toLowerCase().includes(link)) {
      errors.schedulingLinks ? errors.schedulingLinks.push(link) : (errors.schedulingLinks = [link]);
    }
  });
  return errors;
};

const InitialCampaignMessageEditor = ({
  fromEmail,
  toName,
  message,
}: {
  fromEmail?: string | null;
  toName?: string | null;
  message: CampaignMessage;
}): React.ReactElement => {
  const [showVariables, setShowVariables] = useBoolean(false);

  // Jotai state
  const selectedEmailEditorTab = useAtomValue(EmailEditorModeAtom);
  const [campaignMessageSubject, setCampaignMessageSubject] = useAtom(campaignMessageSubjectAtom);
  const [campaignMessageBody, setCampaignMessageBody] = useAtom(campaignMessageBodyAtom);
  const [campaignMessageCcEmails, setCampaignMessageCcEmails] = useAtom(campaignMessageCcEmailsAtom);
  const setCampaignMessageValid = useSetAtom(campaignMessageValidAtom);

  // Local state
  const [initialSubject, setInitialSubject] = useState<string | undefined>(undefined);
  const [initialBody, setInitialBody] = useState<string | undefined>(undefined);

  // Initialize jotai state on component mount
  useEffect(() => {
    setCampaignMessageSubject(getPlainTextFromHtml(message.subjectTemplate ?? ""));
    setCampaignMessageBody(message.bodyTemplate ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize local editor state when either the message prop or the selected tab changes
  useEffect(() => {
    setInitialSubject(campaignMessageSubject ?? getPlainTextFromHtml(message.subjectTemplate ?? ""));
    // Don't re-initialize when the jotai state changes to prevent re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.subjectTemplate, selectedEmailEditorTab]);

  useEffect(() => {
    setInitialBody(campaignMessageBody ?? message.bodyTemplate ?? "");
    // Don't re-initialize when the jotai state changes to prevent re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.bodyTemplate, selectedEmailEditorTab]);

  // Setters to update only jotai state to prevent email editor from re-rendering
  const onSubjectChanged = (newSubject: string): void => {
    setCampaignMessageSubject(newSubject);
  };

  const onBodyChanged = (newBody: string): void => {
    setCampaignMessageBody(newBody);
  };

  const onCcChanged = (newCc: BasicEmailOption[]): void => {
    setCampaignMessageCcEmails(newCc.map(cc => cc.email));
  };

  const errors: any = useMemo(() => {
    const validation = validate(campaignMessageSubject ?? "", campaignMessageBody ?? "");
    setCampaignMessageValid(Object.keys(validation).length === 0);
    return validation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, campaignMessageSubject, campaignMessageBody, setCampaignMessageValid]);

  const ccOptions = useCcOptions();

  const from = {
    id: "",
    email: fromEmail ?? "example@dover.com",
    isAuthed: true,
    owner: EmailOwner.AuthedUser,
  };
  const ccEmailsTipTap = (campaignMessageCcEmails || [])?.map(email => {
    return { email, id: "", isAuthed: true, owner: EmailOwner.AuthedUser };
  });

  // Preview Mode
  if (selectedEmailEditorTab === EmailEditorMode.Preview) {
    const substitutionMap = {
      FIRST_NAME: toName ?? VARIABLES.FIRST_NAME.value,
      DAY_OF_WEEK: VARIABLES.DAY_OF_WEEK.value,
      PERSONALIZED_CONTENT: VARIABLES.PERSONALIZED_CONTENT.value,
      EMAIL_SENDER_NAME: VARIABLES.EMAIL_SENDER_NAME.value,
    };

    const substitutedBody = substituteVariables(campaignMessageBody ?? initialBody, substitutionMap);

    const substitutedSubject = substituteVariables(
      getPlainTextFromHtml(campaignMessageSubject ?? initialSubject ?? ""),
      substitutionMap
    );

    return (
      <EmailEditor showPreviewTab readOnly body={substitutedBody} subject={substitutedSubject} hideSaveTemplateButton />
    );
  }

  return (
    <Stack spacing={1}>
      <Stack direction="column" spacing={1} width="100%" overflow="hidden">
        <Stack spacing={2} minWidth="65%">
          <EmailEditor
            showPreviewTab
            from={from}
            cc={ccEmailsTipTap}
            ccOptions={ccOptions}
            body={initialBody ?? ""}
            subject={initialSubject}
            onBodyChanged={onBodyChanged}
            onSubjectChanged={onSubjectChanged}
            onCcChanged={onCcChanged}
            variables={CAMPAIGN_VARIABLE_KEYS}
            hideSaveTemplateButton
          />
        </Stack>

        <Stack direction="column" spacing={1}>
          <Button
            variant={ButtonVariant.Ghost}
            onClick={(e): void => {
              // necessary to prevent the modal from closing
              e.preventDefault();
              setShowVariables(!showVariables);
            }}
          >
            {showVariables ? "Hide" : "Show"} Variables Help
          </Button>
          <Collapse in={showVariables}>
            <VariableSelector variables={CAMPAIGN_VARIABLE_KEYS} />
          </Collapse>
          {errors.customVariables && (
            <BodySmall color={colors.critical.base}>
              We do not support custom variables. Please select from the variables in the list.
            </BodySmall>
          )}
          {errors.schedulingLinks && (
            <BodySmall color={colors.critical.base}>
              We&apos;re unable to support scheduling links in outreach messages as it disrupts the automated flow we
              have in place. Please remove the scheduling link.
            </BodySmall>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InitialCampaignMessageEditor;
