import { Progress } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC } from "react";

import { CopyCodeSection } from "components/CopyCodeSection";
import { Overline } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { SetupStep } from "views/EditCareersPage/components/CareersPageSetupModal/SetupStep";

export const EmbedSetup: FC = () => {
  const { data, isLoading } = useGetUsersClientQuery();

  let EmbedCode;
  if (isLoading) {
    EmbedCode = <Progress />;
  } else if (!data) {
    EmbedCode = <Overline>Could not load client data</Overline>;
  } else {
    EmbedCode = (
      <CopyCodeSection
        text={`<iframe width="800px" height="700px" src="https://app.dover.com/jobs/${data.slug}?embed=1" frameBorder="0"></iframe>`}
      />
    );
  }

  return (
    <Stack spacing={4}>
      <SetupStep step={1} description="Copy the embed code below:" content={EmbedCode} />
      <SetupStep
        step={2}
        description="Paste the code into the <body> section of your careers page."
        helpText=" Tip: Adjust width and height parameters as needed."
      />
      <SetupStep step={3} description="Publish and verify your careers page works." />
    </Stack>
  );
};
