/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20070
 */
export interface InlineResponse20070 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20070
     */
    hasActiveUser?: boolean;
}

export function InlineResponse20070FromJSON(json: any): InlineResponse20070 {
    return InlineResponse20070FromJSONTyped(json, false);
}

export function InlineResponse20070FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20070 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasActiveUser': !exists(json, 'has_active_user') ? undefined : json['has_active_user'],
    };
}

export function InlineResponse20070ToJSON(value?: InlineResponse20070 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_active_user': value.hasActiveUser,
    };
}


