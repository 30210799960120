import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { LearnMoreLink } from "components/library/LearnMoreLink";
import { Subtitle1 } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useModal } from "GlobalOverlays/atoms";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { addAgencyModalAtom } from "views/agencies/AddAgencyModal";
import { AgenciesTable } from "views/agencies/AgenciesTable";
import { RecruiterMarketplaceSplash } from "views/agencies/RecruiterMarketplaceSplash";

/* -----------------------------------------------------------------------------
 * PrimaryView
 * -------------------------------------------------------------------------- */

const JobAgenciesPage = (): React.ReactElement => {
  const { open } = useModal(addAgencyModalAtom);
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  return (
    <Stack maxWidth="950px" margin="0 auto" spacing={2}>
      <PageHelmet title={job?.title ? `${job.title} - Agencies` : "Agencies"} />
      {/* header */}
      <Stack paddingTop={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1}>
          <Subtitle1>Agencies</Subtitle1>
          <LearnMoreLink
            description="Invite agencies to submit candidates."
            linkUrl="https://help.dover.com/en/articles/10087355-agency-portal"
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              open({});
            }}
          >
            + Agency
          </Button>
        </Stack>
      </Stack>
      <RecruiterMarketplaceSplash />
      <AgenciesTable />
    </Stack>
  );
};

export default JobAgenciesPage;
