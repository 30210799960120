import { Box, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import { DoverLoginSource, doverLoginSourceAtom } from "App/routing/atoms";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CheckIcon } from "assets/icons/simple-green-check.svg";
import { ReactComponent as Logos } from "assets/images/onboarding-rps-logos.svg";
import { ReactComponent as RPContent } from "assets/images/onboarding-rps.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import { TitleExtraLarge, Body, BodySmall } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { colors } from "styles/theme";

export const OnboardingRecruitingPartnersSplash = (): React.ReactElement => {
  const navigate = useNavigate();

  const loginSourceValue = useAtomValue(doverLoginSourceAtom);

  const calendlyUrl = useGetCalendlyUrl("app", "onboarding", "rp-splash");

  const onSkip = (): void => {
    // users that originate from the extension should be redirected to the extension create job flow
    if (loginSourceValue === DoverLoginSource.Extension) {
      navigate({ pathname: APP_ROUTE_PATHS.job.extensionCreateJob() });
    } else {
      navigate({ pathname: APP_ROUTE_PATHS.home() });
    }
  };

  return (
    <>
      <Box position="fixed" top="24px" left="24px">
        <DoverLogo />
      </Box>

      <Stack
        direction="row"
        height="100%"
        width="100%"
        sx={{ backgroundColor: colors.grayscale.gray100 }}
        alignItems="center"
        justifyContent="center"
        spacing={"60px"}
      >
        <Stack height="100%" alignItems="center" justifyContent="center" spacing={4}>
          <Stack
            maxWidth={"653px"}
            overflow="scroll"
            spacing={2}
            p={4}
            sx={{
              border: `1px solid ${colors.white}`,
              borderRadius: "10px",
              background: "white",
              "&:-webkit-scrollbar": "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "&-ms-overflow-style:": {
                display: "none",
              },
            }}
          >
            <Stack spacing={1.5}>
              <TitleExtraLarge weight="500">Find great candidates fast with a fractional recruiter</TitleExtraLarge>
              <Body>Get expert recruiters to help you scale your team quickly and easily.</Body>
              <Stack direction="row" spacing={1}>
                <Stack direction="row" spacing={0.5}>
                  <CheckIcon />
                  <BodySmall color={colors.grayscale.gray600}>No minimums</BodySmall>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <CheckIcon />
                  <BodySmall color={colors.grayscale.gray600}>No commitments</BodySmall>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <CheckIcon />
                  <BodySmall color={colors.grayscale.gray600}>Pause whenever you need</BodySmall>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                variant={ButtonVariant.Primary}
                onClick={(): void => {
                  window.open(calendlyUrl, "_blank", "noopener noreferrer");
                }}
              >
                Schedule Free Intro Call
              </Button>
              <Button
                variant={ButtonVariant.Ghost}
                onClick={(): void => {
                  const rpPageUrl = `${appConfig.appUrl}${APP_ROUTE_PATHS.recruitingPartners()}`;
                  window.open(rpPageUrl, "_blank", "noopener noreferrer");
                }}
              >
                Learn More
              </Button>
            </Stack>

            <Stack pt={3} spacing={1}>
              <BodySmall color={colors.grayscale.gray500}>We&apos;ve helped hire at top companies</BodySmall>
              <Logos />
            </Stack>
          </Stack>
          <Button removePadding variant={ButtonVariant.Ghost} onClick={onSkip}>
            I am not interested
          </Button>
        </Stack>
        <RPContent />
      </Stack>
    </>
  );
};
