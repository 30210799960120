import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { SideBarNavItem } from "App/components/Nav/SideBarNavItemV2";
import { ReactComponent as UserCheckIcon } from "assets/icons/user-check.svg";
import { BodySmall } from "components/library/typography";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";

export const SideNavRecruitingPartners = (): React.ReactElement => {
  const navigate = useNavigate();
  const isFreeCustomer = useIsFreeCustomer();

  if (isFreeCustomer) {
    return (
      <Stack
        component="div"
        p={1.5}
        mb={1}
        onClick={(): void => navigate("/recruiting-partners")}
        sx={{
          borderRadius: "6px",
          background: `linear-gradient(120deg, ${colors.brand}15, ${colors.success.light}30)`,
          cursor: "pointer",
          transition: "all 0.2s ease",
          "&:hover": {
            transform: "translateY(-1px)",
            background: `linear-gradient(120deg, ${colors.brand}25, ${colors.success.light}40)`,
          },
        }}
      >
        <Stack spacing={1}>
          <BodySmall color={colors.grayscale.gray700} weight="500">
            Explore Dover Recruiting Partners
          </BodySmall>
          <BodySmall color={colors.grayscale.gray600}>Hire faster with a world-class fractional recruiter</BodySmall>
        </Stack>
      </Stack>
    );
  }

  return (
    <SideBarNavItem
      to="/recruiting-partners"
      label="My Recruiting Partner"
      icon={<UserCheckIcon width="16px" height="16px" className="svg-color" />}
    />
  );
};
