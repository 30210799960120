import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, IconButton, SelectChangeEvent, Stack } from "@mui/material";
import React from "react";

import { DurationSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/DurationSelect";
import { Button, ButtonVariant } from "components/library/Button";
import { InterviewerPreferencesWarningBanner } from "components/library/TipTap/InterviewerLinkSelector/InterviewerPreferencesWarningBanner";
import { BodySmall } from "components/library/typography";
import { InterviewerOption } from "components/NextInterviewer/useInterviewerOptions";
import { PersonaCoin } from "components/PersonaCoin";

interface InterviewerLinkCreationDetailProps {
  onBack: () => void;
  onInsert: () => void;
  onDurationChange: (e: SelectChangeEvent<number | unknown>) => void;
  duration: number | undefined;
  interviewer: InterviewerOption;
  linkLoading?: boolean;
}

export const InterviewerLinkCreationDetail: React.FC<InterviewerLinkCreationDetailProps> = ({
  onBack,
  onInsert,
  onDurationChange,
  duration,
  interviewer,
  linkLoading,
}) => {
  const showInterviewerPrefWarning = !interviewer.preferencesComplete && !interviewer.isDoverInterviewer;

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <PersonaCoin
          id={interviewer.value}
          firstName={interviewer.firstName || "Unknown"}
          lastName={interviewer.lastName || "Known"}
          pictureUrl={interviewer.pictureUrl}
          useDoverLogo={interviewer.isDoverInterviewer}
          size="small"
        />
        <BodySmall>{interviewer.fullName ?? "-"}</BodySmall>
      </Stack>
      <Divider sx={{ mb: 2 }} />
      {showInterviewerPrefWarning && <InterviewerPreferencesWarningBanner />}
      <DurationSelect
        disabled={interviewer.isDoverInterviewer || !interviewer.preferencesComplete}
        duration={duration}
        disabledTooltipText={
          !interviewer.isDoverInterviewer
            ? "Duration cannot be edited for Dover Interviews"
            : !interviewer.preferencesComplete
            ? "Duration cannot be edited for interviewers with incomplete preferences"
            : undefined
        }
        setDuration={onDurationChange}
      />
      <Box flexGrow={1} />
      <Button
        variant={ButtonVariant.SecondaryLight}
        onClick={onInsert}
        disabled={showInterviewerPrefWarning || linkLoading}
        loading={linkLoading}
      >
        Insert Link
      </Button>
    </>
  );
};
