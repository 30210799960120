import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Chip, Stack, TextField } from "@mui/material";
import React, { useMemo } from "react";

import WarningIconSVG from "assets/icons/warning-outlined.svg";
import { InterviewerLoadingSkeleton } from "components/library/TipTap/InterviewerLinkSelector/InterviewerLoadingSkeleton";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { InterviewerOption } from "components/NextInterviewer/useInterviewerOptions";
import { DOVER_INTERVIEWER_ID } from "components/NextInterviewer/usePossibleInterviewers";
import { PersonaCoin } from "components/PersonaCoin";
import { colors } from "styles/theme";

interface InterviewerListProps {
  onSelect: (id: InterviewerOption | null) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  interviewerOptions: InterviewerOption[] | undefined;
  isFetching: boolean;
}

export const InterviewerList: React.FC<InterviewerListProps> = ({
  onSelect,
  searchQuery,
  setSearchQuery,
  interviewerOptions,
  isFetching,
}) => {
  const filteredInterviewers = useMemo(
    () =>
      isFetching || !interviewerOptions
        ? []
        : interviewerOptions.filter(interviewer =>
            (interviewer.fullName ?? "").toLowerCase().includes(searchQuery.toLowerCase())
          ),
    [searchQuery, isFetching, interviewerOptions]
  );

  return (
    <Box>
      <TextField
        autoFocus
        size="small"
        fullWidth
        placeholder="Search interviewers..."
        value={searchQuery}
        onChange={(e): void => setSearchQuery(e.target.value)}
        onFocus={(e): void => e.target.select()}
        sx={{ mb: 1 }}
      />
      <Box sx={{ mb: 1 }}>
        {isFetching ? (
          <BodyExtraSmall color={colors.grayscale.gray500}>Loading...</BodyExtraSmall>
        ) : (
          <BodyExtraSmall color={colors.grayscale.gray500}>
            {filteredInterviewers.length} interviewer{filteredInterviewers.length !== 1 ? "s" : ""}
          </BodyExtraSmall>
        )}
      </Box>
      <Box>
        {isFetching
          ? [...Array(5)].map((_, index) => <InterviewerLoadingSkeleton key={index} />)
          : filteredInterviewers.map(interviewer => {
              const fn = interviewer.value === DOVER_INTERVIEWER_ID ? "Dover" : interviewer.firstName || "Unknown";
              const ln = interviewer.value === DOVER_INTERVIEWER_ID ? "Interviewer" : interviewer.lastName || "Known";
              const full = interviewer.fullName?.trim() || interviewer.label?.trim();

              return (
                <Stack
                  key={interviewer.value}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                  onClick={(): void => onSelect(interviewer)}
                  sx={{ cursor: "pointer" }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PersonaCoin
                      id={interviewer.value}
                      firstName={fn}
                      lastName={ln}
                      useDoverLogo={interviewer.isDoverInterviewer}
                      pictureUrl={interviewer.pictureUrl}
                      size="small"
                    />

                    {!interviewer.isDoverInterviewer && !interviewer.preferencesComplete && (
                      <Tooltip title="Interviewer has incomplete preferences" placement="top">
                        <img src={WarningIconSVG} alt="Warning" />
                      </Tooltip>
                    )}

                    <BodySmall>{full}</BodySmall>
                    {interviewer.isDefaultInterviewer && (
                      <Chip
                        label="Default"
                        size="small"
                        sx={{
                          height: "20px",
                          backgroundColor: colors.grayscale.gray100,
                          "& .MuiChip-label": {
                            fontSize: "11px",
                            px: 1,
                          },
                        }}
                      />
                    )}
                  </Stack>
                  <BodySmall>
                    <ArrowForwardIosIcon fontSize="inherit" />
                  </BodySmall>
                </Stack>
              );
            })}
      </Box>
    </Box>
  );
};
