// @ts-ignore
import { getEmojiByCurrencyCode } from "country-currency-emoji-flags";
import getSymbolFromCurrency from "currency-symbol-map";

import { JobCompensation } from "services/openapi";

export const displayJobCompensation = (jobCompensation?: JobCompensation): string => {
  if (!jobCompensation || !jobCompensation.lowerBound || !jobCompensation.upperBound) return "Not specified";

  const currencySymbol = currencyCodeToSybmol(jobCompensation.currencyCode);

  return `${currencySymbol}${jobCompensation.lowerBound.toLocaleString()} - ${currencySymbol}${jobCompensation.upperBound.toLocaleString()}`;
};

export const currencyCodeToSybmol = (currencyCode: string): string => {
  return getSymbolFromCurrency(currencyCode) || "$";
};

export const currencyCodeToEmoji = (currencyCode: string): string => {
  return getEmojiByCurrencyCode(currencyCode);
};
