import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";

import { useJobId } from "hooks/useJobIdFromUrl";
import {
  CandidateCountArgs,
  useGetCandidateCountsQuery,
} from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { UseQueryResult } from "services/doverapi/types";
import { CandidateFilterList, CandidatePipelineCountResponse } from "services/openapi";

export type CountMap = Map<string, number> | undefined;

type CandidateCountQueryResponse = UseQueryResult<Array<CandidatePipelineCountResponse>>;

export type CandidateCountMapReturn = Omit<CandidateCountQueryResponse, "data"> & { counts: CountMap };

export const useCandidateCountMapArgs = (
  countFilters: Array<CandidateFilterList> | undefined
): CandidateCountArgs | SkipToken => {
  const [jobId] = useJobId();

  return jobId && countFilters && countFilters.length > 0
    ? {
        useSuperApi: true,
        args: {
          jobId,
          data: {
            countFilters,
          },
        },
      }
    : skipToken;
};

/**
 * This hook should be the main way we call the candidate counts api.
 * It grabs the job id from the url and makes the api call with the supplied count filters.
 * If count filters are undefined it will skip calling the api.
 *
 * It also transforms the response, which is normally just an array of objects, into a map keyed by the name you pass in with the count filters.
 *
 * In addition to the counts map, it also returns all the other rtkq return values.
 *
 * @param countFilters - The filters to apply to the count query. Each filter will end up as a seperate entry in the map, keyed by the name field.
 */
export const useCandidateCountMap = (countFilters: Array<CandidateFilterList> | undefined): CandidateCountMapReturn => {
  const args = useCandidateCountMapArgs(countFilters);

  return useGetCandidateCountsQuery(args, { selectFromResult: candidateCountToMap });
};

/**
 * This is a selector to transform the array of candidate counts into a map keyed by the name field.
 */
const candidateCountToMap = ({ data, ...rest }: CandidateCountQueryResponse): CandidateCountMapReturn => {
  const counts: Map<string, number> | undefined = data?.reduce(
    (map, { name, count }) => map.set(name, count),
    new Map<string, number>()
  );

  return { counts, ...rest };
};
