import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  width?: string | number;
  color?: string;
}

const StyledLine = styled(Box)<Props>`
  background-color: ${({ color }): string => color ?? "black"};
`;

export const DividingLine = ({ width = "64px", color }: Props): React.ReactElement => {
  return <StyledLine width={width} height="1px" color={color} />;
};
