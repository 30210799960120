import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";

import { ReactComponent as AIWandSVG } from "assets/icons/ai-wand-gradient.svg";
import { DividingLine } from "components/DividingLine";
import { GenerateCareersPageErroredAtom, ShowCareersPageGeneration } from "components/dover/EditCareersPage/atoms";
import CareersPageStatsSection from "components/dover/EditCareersPage/CareersPageStatsSection";
import { EditCareersPageSchemaFormType } from "components/dover/EditCareersPage/types";
import CompanyLogo from "components/ImageUpload/CompanyLogo";
import { Banner, BannerVariant } from "components/library/Banner";
import { TextField } from "components/library/TextField";
import EditorWithMenu from "components/library/TipTap/EditorWithMenu";
import { Body, BodySmall, Caption, Subtitle1 } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { colors } from "styles/theme";
import { getHtmlFromUnknownContent } from "utils/draftJS";
import { CAREERS_PAGE_SCROLLABLE_CONTAINER_ID } from "views/EditCareersPage/components/EditCareersPageFormWrapper";

const EditCareersPageForm = ({ inOnboardingFlow }: { inOnboardingFlow?: boolean }): React.ReactElement => {
  const [initialCompanyInfo, setInitialCompanyInfo] = React.useState<string>("");
  const useSlugPoweredCareersPage = useFeatureFlag(FeatureFlag.SlugPoweredCareersPageUrls);
  const { control } = useFormContext<EditCareersPageSchemaFormType>();

  const { field: logoField } = useController({
    name: "logo",
    control,
  });

  const { field: companyInfoField } = useController({
    name: "companyInfo",
    control,
  });

  const { field: companyUrlField } = useController({
    name: "companyUrl",
    control,
  });

  const { field: companySlugField } = useController({
    name: "companySlug",
    control,
  });

  const { errors } = useFormState({ control });

  useEffect(() => {
    setInitialCompanyInfo(companyInfoField.value ? getHtmlFromUnknownContent(companyInfoField.value) : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isErrorGeneratingCareersPage = useAtomValue(GenerateCareersPageErroredAtom);
  const setShowCareersPageGeneration = useSetAtom(ShowCareersPageGeneration);

  return (
    <Stack width="100%" spacing={3} alignItems="start" className="hide-scrollbar">
      <Stack width="50%" spacing={1} height="100%">
        <Subtitle1>{"Company Logo"}</Subtitle1>
        <CompanyLogo logoInput={logoField.value} setLogoInput={logoField.onChange} isPreviewThumbnail />
      </Stack>
      {!inOnboardingFlow && (
        <Stack spacing={1} width="50%">
          <Subtitle1>Homepage URL</Subtitle1>
          <TextField
            text={companyUrlField.value ?? undefined}
            onTextUpdated={companyUrlField.onChange}
            error={!!errors.companyUrl}
            errorMessage={errors.companyUrl?.message}
          />
          <Caption color={colors.grayscale.gray600}>Your logo will link here</Caption>
        </Stack>
      )}
      {!inOnboardingFlow && useSlugPoweredCareersPage && (
        <Stack spacing={1} width="50%">
          <Subtitle1>Custom URL Slug</Subtitle1>
          <TextField
            text={companySlugField.value ?? undefined}
            onTextUpdated={companySlugField.onChange}
            error={!!errors.companySlug}
            errorMessage={errors.companySlug?.message}
            inputProps={{ maxLength: 50 }}
          />
          <Caption color={colors.grayscale.gray600}>
            Your careers page will be accessible at {appConfig.appDomain}/jobs/{companySlugField.value}
          </Caption>
        </Stack>
      )}

      <Stack width="100%">
        {isErrorGeneratingCareersPage ? (
          <Banner variant={BannerVariant.Warning} noIcon>
            <Stack direction="row" alignItems="center" width="100%">
              <BodySmall>
                We weren&apos;t able to gather enough information. Please make relevant changes below.
              </BodySmall>
            </Stack>
          </Banner>
        ) : (
          <Stack spacing={3}>
            <DividingLine width={"100%"} color={colors.grayscale.gray200} />

            <Button
              variant="primaryOutlined"
              onPress={(): void => setShowCareersPageGeneration(true)}
              p={1}
              icon={{ Icon: AIWandSVG }}
            >
              <BodySmall>Re-generate careers page using different URL</BodySmall>
            </Button>
          </Stack>
        )}
      </Stack>

      <CareersPageStatsSection />
      <Stack width="100%" spacing={1}>
        <Stack>
          <Subtitle1>{"Company Description"}</Subtitle1>
          <Body color={colors.grayscale.gray600}>
            {"Add information to emphasize aspects of your company such as values, location, benefits, etc."}
          </Body>
        </Stack>
        <EditorWithMenu
          initialContent={initialCompanyInfo}
          onContentChange={companyInfoField.onChange}
          placeholder="Enter company info here..."
          scrollableContainerId={CAREERS_PAGE_SCROLLABLE_CONTAINER_ID}
          enableImages
        />
      </Stack>
    </Stack>
  );
};

export default EditCareersPageForm;
