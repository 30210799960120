import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as CheckedMailIcon } from "assets/icons/email_with_check.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { useSendCareersPageInstructionsMutation } from "services/doverapi/endpoints/careersPageClient";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";

type EmailSetupProps = {
  onSend?: () => void;
};
export const EmailSetup: FC<React.PropsWithChildren<EmailSetupProps>> = ({ onSend }: EmailSetupProps) => {
  const [sendCareersPageInstructions, { isLoading }] = useSendCareersPageInstructionsMutation();

  const [recipientName, setRecipientName] = React.useState("");
  const [recipientEmail, setRecipientEmail] = React.useState("");
  const [additionalNotes, setAdditionalNotes] = React.useState("");

  const [success, setSuccess] = React.useState(false);

  const send = async (): Promise<void> => {
    const res = await sendCareersPageInstructions({
      additionalNotes,
      recipientEmail,
      recipientName,
    }).unwrap();

    if (res.success) {
      onSend?.();
      setSuccess(true);
    } else {
      showErrorToast(res.message ?? "Failed to send instructions");
    }
  };

  const buttonDisabled = !recipientName || !recipientEmail;

  if (success) {
    return (
      <Stack spacing={1.5} alignItems={"center"} justifyContent={"center"} minHeight={"294px"}>
        <CheckedMailIcon height="50px" width="50px" />
        <BodySmall color={colors.grayscale.gray600}>Email sent!</BodySmall>
      </Stack>
    );
  }
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <BodySmall color={colors.grayscale.gray500}> Send set up instructions to your team</BodySmall>
        <Button
          variant={ButtonVariant.Link}
          removeOutline
          removePadding
          onClick={(): void => {
            window.open("https://help.dover.com/en/articles/6480726-careers-page", "_blank");
          }}
        >
          <BodySmall color={colors.link}>(preview)</BodySmall>
        </Button>
      </Stack>
      <TextField text={recipientName} placeholderText="Name" onTextUpdated={setRecipientName} />
      <TextField text={recipientEmail} placeholderText="Email" onTextUpdated={setRecipientEmail} />

      <BodySmall color={colors.grayscale.gray500}>Additional instructions (optional)</BodySmall>
      <TextField multiline minRows={3} text={additionalNotes} onTextUpdated={setAdditionalNotes} />
      <Stack width="100%" alignItems="flex-end">
        <Button
          width="fit-content"
          removeOutline
          onClick={send}
          loading={isLoading}
          variant={ButtonVariant.SecondarySuccess}
          disabled={buttonDisabled}
          tooltip={buttonDisabled ? "Name and Email are required" : undefined}
        >
          Send Email
        </Button>
      </Stack>
    </Stack>
  );
};
