import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Popover, Stack, SelectChangeEvent } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState, useCallback, useEffect } from "react";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { Button, ButtonVariant } from "components/library/Button";
import {
  POPOVER_WIDTH,
  POPOVER_MIN_HEIGHT,
  POPOVER_MAX_HEIGHT,
  DEFAULT_INTERVIEW_DURATION,
} from "components/library/TipTap/InterviewerLinkSelector/constants";
import { InterviewerLinkCreationDetail } from "components/library/TipTap/InterviewerLinkSelector/InterviewerLinkCreationDetail";
import { ListView } from "components/library/TipTap/InterviewerLinkSelector/ListView";
import { InterviewerLinkSelectorProps } from "components/library/TipTap/InterviewerLinkSelector/types";
import { BodySmall } from "components/library/typography";
import { InterviewerOption, useInterviewerOptions } from "components/NextInterviewer/useInterviewerOptions";
import { useGetSchedulingLinkQuery } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";

/**
 * InterviewerLinkSelector component allows users to insert scheduling links for interviewers into the editor.
 * It provides a searchable list of available interviewers and customizable interview duration settings.
 */
const InterviewerLinkSelector: React.FC<InterviewerLinkSelectorProps> = ({
  editor,
  improvedSchedulingLinkConfig,
  disabled,
  onSchedulingLinkInserted,
}) => {
  // Local state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedInterviewer, setSelectedInterviewer] = useState<InterviewerOption | null>(null);
  const [interviewDuration, setInterviewDuration] = useState<number | undefined>(DEFAULT_INTERVIEW_DURATION);
  const open = Boolean(anchorEl);
  const id = open ? "interviewer-link-selector-popover" : undefined;

  // Fetched data
  const {
    options: { data: interviewerOptions, isFetching: isFetchingInterviewers },
  } = useInterviewerOptions({
    desiredHiringPipelineStage: improvedSchedulingLinkConfig?.stage,
    excludeDoverInterviewer: improvedSchedulingLinkConfig?.customerManagedScheduling,
    applySchedulingOrdering: true,
    excludeCreateNew: true,
    excludeIncompletePreferences: false, // Might change this later once we work out copy / flow changes
  });
  const { data: schedulingLink, isFetching: isFetchingSchedulingLink } = useGetSchedulingLinkQuery(
    selectedInterviewer?.value && selectedInterviewer.preferencesComplete
      ? {
          candidateId: improvedSchedulingLinkConfig?.candidateId!,
          interviewerId: selectedInterviewer.value,
          desiredHiringPipelineStageId: improvedSchedulingLinkConfig?.desiredHiringPipelineStageId,
          interviewDurationSeconds: interviewDuration,
        }
      : skipToken
  );

  // Handlers
  const handleDurationChange = (e: SelectChangeEvent<number | unknown>): void => {
    setInterviewDuration(e.target.value as number);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleInsertLink = useCallback(async () => {
    if (editor && schedulingLink) {
      if (onSchedulingLinkInserted) {
        onSchedulingLinkInserted(selectedInterviewer!);
      }

      editor.commands.insertContent(`<a href="${schedulingLink.link}">${schedulingLink.link}</a>`);
    }
  }, [editor, schedulingLink, onSchedulingLinkInserted, selectedInterviewer]);

  // Effects
  useEffect(() => {
    // set initial duration to substage duration that we're passing down
    setInterviewDuration(improvedSchedulingLinkConfig?.interviewDuration ?? DEFAULT_INTERVIEW_DURATION);
  }, [improvedSchedulingLinkConfig?.interviewDuration]);

  // Component structure:
  // - Button
  // - Popover
  // - List view
  //  - Header
  //  - List with search box
  //  - Footer
  // - Detail view
  //  - Header - selected interviewer
  //  - Duration select
  //  - Insert button
  return (
    <>
      <Button variant={ButtonVariant.SecondaryLight} onClick={handleClick} disabled={disabled}>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <CalendarIcon />
          <BodySmall weight="500">Add Scheduling Link</BodySmall>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Stack>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: `${POPOVER_WIDTH}px`,
            minHeight: `${POPOVER_MIN_HEIGHT}px`,
            maxHeight: `${POPOVER_MAX_HEIGHT}px`,
          },
        }}
      >
        {selectedInterviewer === null ? (
          <ListView
            onSelect={setSelectedInterviewer}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isFetching={isFetchingInterviewers}
            interviewerOptions={interviewerOptions}
          />
        ) : (
          <Stack direction="column" sx={{ width: "300px", minHeight: "250px" }} spacing={1} p={2}>
            <InterviewerLinkCreationDetail
              onBack={(): void => setSelectedInterviewer(null)}
              onInsert={handleInsertLink}
              onDurationChange={handleDurationChange}
              duration={interviewDuration}
              interviewer={selectedInterviewer}
              linkLoading={isFetchingSchedulingLink}
            />
          </Stack>
        )}
      </Popover>
    </>
  );
};

export default InterviewerLinkSelector;
