import { Stack } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

// Due to difficult icon styling, we opt for a wrapper component that can style the entire UI for this item based on the class of the NavLink
const StyledSideBarNavItemWrapper = styled(Stack)`

a {
    border: 1px solid transparent;
    border-radius: 4px;
    text-decoration: none !important;
    div {
      color: ${colors.grayscale.gray600} !important;
      font-weight: 400 !important;
    }
    svg.svg-color path {
        stroke: color: ${colors.grayscale.gray600} !important;
    }
    svg.svg-fill path {
        fill: color: ${colors.grayscale.gray600} !important;
      }

    :hover {
        background-color: ${colors.grayscale.gray200};
    }
  }

  .side-bar-nav-item-active {
    :hover {
        background-color:  ${colors.white};
    }
    background-color: ${colors.white};
    border-radius: 6px;
    border: 1px solid ${colors.grayscale.gray200};
    box-shadow: 0px 1px 4px 0px rgba(0 0 0 / 0.05);

    div {
      color: ${colors.grayscale.gray600} !important;
      font-weight: 500 !important;

      svg {
        color: ${colors.brand} !important;
      }
    }
  }
`;

interface SideBarNavItemProps {
  icon?: React.ReactNode;
  endAdornment?: React.ReactNode;
  label: string;
  to: string;
  // Requries exact match for route
  end?: boolean;
}

/**
 * An item in the sidebar used for navigation
 * Displays backplate and distinguished text for the selected item
 */
export const SideBarNavItem = ({
  icon: Icon,
  endAdornment: EndAdornment,
  label,
  to,
  end,
}: SideBarNavItemProps): React.ReactElement => {
  return (
    <StyledSideBarNavItemWrapper>
      <NavLink
        end={end}
        to={to}
        className={({ isActive }): string | undefined => (isActive ? "side-bar-nav-item-active" : undefined)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{ padding: "4px", borderRadius: "4px", width: "100%" }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5} minWidth={0}>
            {/* Only render icon if it's defined */}
            {Icon && Icon}
            <BodySmall
              style={{
                // restrict text to 2 lines
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {label}
            </BodySmall>
          </Stack>
          {EndAdornment && EndAdornment}
        </Stack>
      </NavLink>
    </StyledSideBarNavItemWrapper>
  );
};
