import { variables } from "components/outreach-configuration/form/constants";

export const calendlyRegex = new RegExp("calendly.com", "i");
export const savvyCalRegex = new RegExp("savvycal.com", "i");
export const candidateFirstNameTemplateVarRegex = new RegExp("{{ ?FIRST_NAME ?}}", "i");
export const subjectSenderNameRegex = new RegExp("{{ ?EMAIL_SENDER_NAME ?}}", "i");
export const minNumberNameAppears = 1;
export const emailSenderNameRegex = new RegExp("{{EMAIL_SENDER_NAME}}", "g");
export const validTags: Array<string> = Object.keys(variables).map(tag => tag.toUpperCase());
export const tagRegex = /\{+([^{}]*)\}+/g;
