/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewMeetingType {
    GOOGLE_MEET = 'GOOGLE_MEET',
    PHONE = 'PHONE',
    WHEREBY = 'WHEREBY',
    ZOOM = 'ZOOM',
    IN_PERSON = 'IN_PERSON'
}

export function InterviewMeetingTypeFromJSON(json: any): InterviewMeetingType {
    return InterviewMeetingTypeFromJSONTyped(json, false);
}

export function InterviewMeetingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewMeetingType {
    return json as InterviewMeetingType;
}

export function InterviewMeetingTypeToJSON(value?: InterviewMeetingType | null): any {
    return value as any;
}

