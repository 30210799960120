import { Stack } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import { AntSwitch } from "components/StyledMuiComponents";

export interface SwitchControlProps {
  checked: boolean;
  disabled: boolean;
  updating: boolean;
  onChange: (event: React.ChangeEvent<{}>, newValue: boolean) => void;
  label: React.ReactNode;
  updatingLabel?: React.ReactNode;
}

const SwitchControl: React.FC<SwitchControlProps> = ({
  checked,
  disabled,
  updating,
  label,
  updatingLabel,
  onChange,
}) => {
  return (
    <Stack direction="row" alignItems={"center"} justifyContent={"left"} spacing={1}>
      <AntSwitch checked={checked} disabled={disabled || updating} onChange={onChange} />
      <BodySmall>{updating ? updatingLabel ?? "Saving" : label}</BodySmall>
    </Stack>
  );
};

export default SwitchControl;
