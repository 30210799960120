import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as PencilEditSVG } from "assets/icons/pencil-edit.svg";
import CareersPageInfoExampleSVG from "assets/images/careers-page-info-example.svg";
import { careersPageStatsDrawerAtom } from "components/dover/EditCareersPage/CareersPageStatsDrawer";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Overline, Subtitle1 } from "components/library/typography";
import { SocialLink, SocialLinks } from "components/SocialLinks";
import { useModal } from "GlobalOverlays/atoms";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateCareersPageDisplayStatsMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { CareersPageDisplayStat, ClientSocialLink } from "services/openapi";
import { colors } from "styles/theme";

export const ExampleTooltip = (): React.ReactElement => {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <Tooltip
        title={
          <Stack spacing={0.5}>
            <Overline color={colors.white}>Example</Overline>
            <img src={CareersPageInfoExampleSVG} alt="Example of company info" width="275px" />
          </Stack>
        }
      >
        <BodySmall color={colors.grayscale.gray700} textDecoration="underline">
          View example
        </BodySmall>
      </Tooltip>
    </Box>
  );
};

const CareersPageStatsCard = (): React.ReactElement => {
  const [updateCareersPageDisplayStatsMutation] = useUpdateCareersPageDisplayStatsMutation();

  const clientId = useGetClientId();
  const { displayStats, socialLinks, isFetching } = useGetClientOnboardingQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        displayStats: data?.careersPageDisplayStats,
        socialLinks: data?.careersPageSocialLinks,
        isFetching,
      };
    },
  });

  const { open } = useModal(careersPageStatsDrawerAtom);

  if (isFetching) {
    return <Skeleton height="90px" width="100%" />;
  }

  const onSave = async (displayStats: CareersPageDisplayStat[], socialLinks: ClientSocialLink[]): Promise<void> => {
    if (clientId) {
      await updateCareersPageDisplayStatsMutation({
        id: clientId,
        careersPageDisplayStats: displayStats,
        careersPageSocialLinks: socialLinks,
      });
    }
  };

  const noExistingStatsOrSocialLinks =
    (displayStats === undefined || displayStats.length === 0) &&
    (socialLinks === undefined || socialLinks.length === 0);

  // If no stats or social links are present, display a button to add stats
  if (noExistingStatsOrSocialLinks) {
    return (
      <Button
        variant={ButtonVariant.Secondary}
        onClick={(): void =>
          open({
            initialDisplayStats: displayStats,
            initialSocialLinks: socialLinks,
            onSave,
          })
        }
        width="fit-content"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <PencilEditSVG width="16px" />
          <BodySmall>{"Add info"}</BodySmall>
        </Stack>
      </Button>
    );
  }

  return (
    <Stack
      width="100%"
      onClick={(): void =>
        open({
          initialDisplayStats: displayStats,
          initialSocialLinks: socialLinks,
          onSave,
        })
      }
    >
      <Card padding="8px" hoverable>
        <Stack direction="row" justifyContent="space-between" width="100%" spacing={2}>
          <Grid container direction="row" rowSpacing={1} columnSpacing={2}>
            {displayStats &&
              displayStats.map((stat, index) => (
                <Grid item key={index}>
                  <Overline color={colors.grayscale.gray500}>{stat.label}</Overline>
                  <BodySmall color={colors.grayscale.gray700}>{stat.value}</BodySmall>
                </Grid>
              ))}
            {socialLinks && socialLinks.length > 0 && (
              <Grid item>
                <Stack spacing={0.25}>
                  <Overline color={colors.grayscale.gray500}>{"Links"}</Overline>
                  {/* TODO: don't type cast -- export generic SocialLinkLinkTypeEnum from BE */}
                  <SocialLinks socialLinks={(socialLinks as unknown) as SocialLink[]} />
                </Stack>
              </Grid>
            )}
          </Grid>
          <Box sx={{ cursor: "pointer" }}>
            <PencilEditSVG width="16px" />
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};

const CareersPageStatsSection = (): React.ReactElement => {
  return (
    <Stack width="100%" spacing={1}>
      <Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Subtitle1>{"Company Info"}</Subtitle1>
          <Body>(optional)</Body>
          <ExampleTooltip />
        </Stack>
        <Body color={colors.grayscale.gray600}>
          {
            "Display brief pieces of content about your company. This information will display directly below the header."
          }
        </Body>
      </Stack>
      <CareersPageStatsCard />
    </Stack>
  );
};

export default CareersPageStatsSection;
