import { Box, Divider, Stack } from "@mui/material";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { POPOVER_WIDTH } from "components/library/TipTap/InterviewerLinkSelector/constants";
import { InterviewerList } from "components/library/TipTap/InterviewerLinkSelector/InterviewerList";
import { ListViewProps } from "components/library/TipTap/InterviewerLinkSelector/types";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { InternalLink } from "styles/typography";

export const ListView: React.FC<ListViewProps> = ({
  onSelect,
  searchQuery,
  setSearchQuery,
  isFetching,
  interviewerOptions,
}) => (
  <Stack direction="column" sx={{ width: POPOVER_WIDTH, height: "400px" }}>
    <Box p={2}>
      <Stack alignItems="center" direction="row" spacing={0.5} mb={1}>
        <BodySmall>Available Interviewers</BodySmall>
        <Tooltip title="Insert scheduling link for interviewer into email" placement="top">
          <InfoIcon />
        </Tooltip>
      </Stack>
      <Divider />
    </Box>
    <Box sx={{ flexGrow: 1, overflowY: "auto", px: 2 }}>
      <InterviewerList
        onSelect={onSelect}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isFetching={isFetching}
        interviewerOptions={interviewerOptions}
      />
    </Box>
    <Box p={2}>
      <Divider sx={{ mb: 1 }} />
      <InternalLink
        $variant="primary"
        to={APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.MEMBERS)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <BodySmall>Invite Interviewer</BodySmall>
          <ExternalLinkIcon />
        </Stack>
      </InternalLink>
    </Box>
  </Stack>
);
