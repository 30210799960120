import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import ClientEmailTemplateSelector, {
  ClientEmailTemplateSelectorProps,
} from "components/dover/EmailTemplates/ClientEmailTemplateSelector";
import { useSetDefaultStageTemplate } from "components/dover/top-level-modal-manager/hooks/useSetDefaultStageTemplate";
import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import SwitchControl from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/SwitchControl";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";

export const ClientEmailTemplateSelect: React.FC<React.PropsWithChildren<ClientEmailTemplateSelectorProps>> = ({
  disabled,
  disabledTooltipText,
  hideSmartReply,
  clientEmailTemplateId,
  setClientEmailTemplateId,
  candidateId,
  showSetAsDefault,
}) => {
  const { data: bio, isFetching: isFetchingBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const { stage: stageWithMaybeStaleSchedulingTemplate } = useStage({ candidateId });
  const stageId = stageWithMaybeStaleSchedulingTemplate?.id ?? "";
  // HACK: Due to messed up logic in the useStage hook, optimistic stage scheduling templates updates don't propogate to the stage object. Pull the stage from the bio instead directly.
  const stage = bio?.jobStages?.find(s => s.id === stageId);
  const stageSpecificSchedulingTemplateId = stage?.schedulingEmailTemplateId;
  // ?? becaues api returns undefined but the select expects null
  const checked = (clientEmailTemplateId ?? null) === (stageSpecificSchedulingTemplateId ?? null);

  const { setDefaultStageTemplate, updatingDefaultTemplate } = useSetDefaultStageTemplate({
    stageId,
    newTemplateId: clientEmailTemplateId,
    candidateId,
    invalidateCandidateBio: false, // Would cause the entire modal to refetch
    optimisticUpdateCandidateBio: true, // Requires hack you see above to avoid stale data (or a future refactor of the stage state hook)
  });

  return (
    <Stack direction="column" spacing={1} width="100%">
      <Stack spacing={1} width="100%">
        <BodySmall weight="500">Template</BodySmall>
        <Tooltip title={disabledTooltipText}>
          <div>
            <ClientEmailTemplateSelector
              disabled={disabled}
              hideSmartReply={hideSmartReply}
              hideLabel
              clientEmailTemplateId={clientEmailTemplateId}
              setClientEmailTemplateId={setClientEmailTemplateId}
            />
          </div>
        </Tooltip>
      </Stack>
      {showSetAsDefault && !isFetchingBio && stage && (
        <Tooltip
          title="Select a different template to change the default for this stage"
          placement="bottom-start"
          disabled={!checked}
        >
          <Box sx={{ paddingLeft: "4px" }}>
            <SwitchControl
              checked={checked}
              disabled={checked || Boolean(disabled) || updatingDefaultTemplate}
              onChange={(_e, newValue): void => {
                // You can only set the default template for the stage you are currently on
                // You cannot unset the default template
                if (newValue) {
                  setDefaultStageTemplate();
                }
              }}
              updating={updatingDefaultTemplate}
              updatingLabel="Updating..."
              label={
                checked ? (
                  <Stack alignItems="center" direction="row" spacing={0.5}>
                    <BodySmall weight="300">
                      Default for <strong>{stage.name}</strong>
                    </BodySmall>
                    <InfoIcon />
                  </Stack>
                ) : (
                  <BodySmall weight="300">
                    Set as default for <strong>{stage.name}</strong>
                  </BodySmall>
                )
              }
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
