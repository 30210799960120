import { Stack } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ApplicantsIcon } from "assets/icons/inbox.svg";
import { ReactComponent as CandidatesIcon } from "assets/icons/users.svg";
import GoalHireDateButton from "components/GoalHireDateButton";
import { EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { Card } from "components/library/Card";
import { Body, BodySmall } from "components/library/typography";
import { UserAvatarCoin } from "components/PersonaCoin";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { SampleChip } from "views/candidates/ApplicationReview/components/SampleChip";

export const JobCard = ({ job }: { job: DashboardJob }): React.ReactElement => {
  const navigate = useNavigate();

  const { hiringManager, recruiter, recruitingPartner } = job;

  const shouldShowRecruitingPartner = React.useMemo(() => {
    if (!recruitingPartner) return false;
    return recruitingPartner.email !== "george@dover.com";
  }, [recruitingPartner]);

  return (
    <StyledLinkWrapper to={APP_ROUTE_PATHS.job.overview(job.id!)}>
      <Card padding="8px 16px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Stack direction="row" spacing={2} alignItems="flex-start">
              <Body weight="600">{job.title}</Body>
              {job.isSample && <SampleChip tooltipText="We've created a sample job so you can experiment with Dover" />}
              {job.isPrivate && <EyeCrossedOutIcon />}
            </Stack>
            {/* direct links */}
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent="flex-start"
              alignItems="center"
              onClick={(e): void => {
                e.preventDefault();
              }}
              position="inherit"
            >
              <Stack
                direction="row"
                py={0.5}
                spacing={0.5}
                alignItems="center"
                onClick={(e): void => {
                  e.preventDefault();
                  navigate(APP_ROUTE_PATHS.job.applicationReviewV2(job.id!));
                }}
                zIndex={1}
                sx={{
                  "&:hover": {
                    div: {
                      color: colors.link,
                      textDecoration: "underline",
                    },
                    svg: {
                      path: {
                        fill: colors.link,
                      },
                    },
                  },
                }}
              >
                <ApplicantsIcon />
                <BodySmall color={colors.grayscale.gray600}>Applicants</BodySmall>
              </Stack>
              <Stack
                direction="row"
                px={1}
                py={0.5}
                spacing={0.5}
                alignItems="center"
                onClick={(e): void => {
                  e.preventDefault();
                  navigate(APP_ROUTE_PATHS.job.candidates.candidatesTable(job.id!));
                }}
                sx={{
                  "&:hover": {
                    div: {
                      color: colors.link,
                      textDecoration: "underline",
                    },
                    svg: {
                      path: {
                        fill: colors.link,
                      },
                    },
                  },
                }}
              >
                <CandidatesIcon color={colors.grayscale.gray600} />
                <BodySmall color={colors.grayscale.gray600}>Candidates</BodySmall>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={1}>
            <GoalHireDateButton jobId={job.id!} />
            <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
              {hiringManager && <UserAvatarCoin user={hiringManager} label="Hiring Manager" />}
              {recruiter && <UserAvatarCoin user={recruiter} label="Recruiter" />}
              {shouldShowRecruitingPartner && <UserAvatarCoin user={recruitingPartner!} label="Recruiting Partner" />}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </StyledLinkWrapper>
  );
};

const StyledLinkWrapper = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;

    & > div {
      background-color: ${colors.grayscale.gray025};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }
  }
`;
