/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendInstructions
 */
export interface SendInstructions {
    /**
     * 
     * @type {string}
     * @memberof SendInstructions
     */
    recipientEmail: string;
    /**
     * 
     * @type {string}
     * @memberof SendInstructions
     */
    recipientName: string;
    /**
     * 
     * @type {string}
     * @memberof SendInstructions
     */
    additionalNotes?: string;
}

export function SendInstructionsFromJSON(json: any): SendInstructions {
    return SendInstructionsFromJSONTyped(json, false);
}

export function SendInstructionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendInstructions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipientEmail': json['recipient_email'],
        'recipientName': json['recipient_name'],
        'additionalNotes': !exists(json, 'additional_notes') ? undefined : json['additional_notes'],
    };
}

export function SendInstructionsToJSON(value?: SendInstructions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient_email': value.recipientEmail,
        'recipient_name': value.recipientName,
        'additional_notes': value.additionalNotes,
    };
}


