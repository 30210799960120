import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Stack } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React, { FC, useState } from "react";

import { colors } from "styles/theme";

interface CopyCodeSectionProps {
  text: string;
}

export const CopyCodeSection: FC<CopyCodeSectionProps> = ({ text }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: 1, backgroundColor: colors.grayscale.gray100, cursor: "pointer" }}
      onClick={(): void => {
        setClicked(true);
        clipboardCopy(text);
      }}
    >
      <Box sx={{ fontFamily: "Courier New", color: colors.grayscale.gray500 }}>{text}</Box>
      {clicked ? <CheckIcon /> : <ContentCopyIcon />}
    </Stack>
  );
};
