import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as RecruitingPartnerSVG } from "assets/images/agencies-rp.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { BodyExtraSmall, BodySmall, Subtitle1 } from "components/library/typography";
import { colors } from "styles/theme";

export const RecruiterMarketplaceSplash = (): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "agencies", "match_with_a_recruiter");

  return (
    <Stack
      p={2}
      sx={{
        border: `1px solid ${colors.grayscale.gray200}`,
        borderRadius: "10px",
        background: "linear-gradient(#F4F6F8 10%, rgba(244, 246, 248, 0) 100%)",
      }}
    >
      <Stack width="100%" direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <RecruitingPartnerSVG />
        <Stack width="45%">
          <Subtitle1 color={colors.grayscale.gray500}>Try our Recruiter Marketplace</Subtitle1>
          <BodySmall color={colors.grayscale.gray400}>
            Get help from an expert recruiter - from finding candidates to handling logistics, we make recruiting
            effortless. Fully flexible, no minimum commitment.
          </BodySmall>
        </Stack>
        <Stack height="fit-content" direction="row" spacing={0.5}>
          <Button variant={ButtonVariant.SecondarySuccess}>
            <BodyExtraSmall
              weight="500"
              color={colors.brand}
              onClick={(): void => {
                window.open(calendlyUrl, "_blank", "noopener noreferrer");
              }}
            >
              MATCH WITH A RECRUITER
            </BodyExtraSmall>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
