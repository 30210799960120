import { Button } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom, useSetAtom } from "jotai";
import { isEqual } from "lodash";
import React, { ReactElement, useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

import { NEXT_APPLICATION, PREV_APPLICATION } from "App/hotkeys/hotkeys";
import { useHotkey } from "App/hotkeys/useHotkey";
import { ReactComponent as MagnifyingGlassSVG } from "assets/icons/magnifying-glass.svg";
import { CREATE_JOB_DRAWER_OPEN_PARAM } from "components/dover/CreateJob/constants";
import { Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import { useDebounceState } from "hooks/useDebounceState";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListJobsQuery, selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { prioritizeActiveJobsSort } from "services/doverapi/endpoints/job/utils";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3, SearchV3Params, SearchV3SearchTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { CriteriaMode, criteriaModeAtom } from "views/candidates/ApplicationReview/atoms/chat";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";
import { toNextApplicationAtom } from "views/candidates/ApplicationReview/atoms/listApplicationArgs";
import { isMainPanelOpenMobileAtom } from "views/candidates/ApplicationReview/atoms/mobileFriendly";
import { saapParamsAtom } from "views/candidates/ApplicationReview/atoms/saapParams";
import { searchIdAtom } from "views/candidates/ApplicationReview/atoms/searchId";
import { ApplicantListPanel } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantListPanel";
import { CriteriaPanel } from "views/candidates/ApplicationReview/components/CriteriaPanel/CriteriaPanel";
import { MainPanel } from "views/candidates/ApplicationReview/components/MainPanel/MainPanel";
import { applicationIdQueryParam } from "views/candidates/ApplicationReview/constants";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { useReset } from "views/candidates/ApplicationReview/hooks/useReset";
import { incrementNumActionedCandidatesAtom } from "views/candidates/ApplicationReview/hooks/useSaapNumActioned";
import { useSelectedTabCount } from "views/candidates/ApplicationReview/hooks/useSelectedTabCount";
import { findApplicationIndex } from "views/candidates/ApplicationReview/utils/findApplicationIndex";
import { FILTERS_WIDTH, searchV3FormDefaultValues } from "views/sourcing/Search/constants";
import { FormLoadStateContext, FormLoadStateWrapper } from "views/sourcing/Search/context/FilterToggleContext";
import { useLoadFormValues } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

const ApplicationReviewMainNew = (): React.ReactElement => {
  const [, setCreateJobDrawerOpen] = useQueryParam(CREATE_JOB_DRAWER_OPEN_PARAM, BooleanParam);
  const jobIdFromUrl = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobIdFromUrl ?? skipToken);

  const setIsDtn = useSetAtom(isDtnMode);
  setIsDtn(false);
  const setSearchId = useSetAtom(searchIdAtom);

  const { data: authedProUser } = useGetAuthedUserInfoQuery();

  const { allJobs, isLoading: isJobsLoading } = useListJobsQuery(jobIdFromUrl ? skipToken : undefined, {
    selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { sortFunc: prioritizeActiveJobsSort }),
  });

  const [, setJobId] = useQueryParam("jobId", StringParam);

  // if no jobID was provided in URL, set it ourselves based on RTK results above
  const jobId = React.useMemo(() => {
    if (jobIdFromUrl) {
      return jobIdFromUrl;
    }

    // Waiting on info to load
    if (!allJobs || !authedProUser) {
      return undefined;
    }

    // If no job ID from url, just set the new one and wait for next render. jobId comes from URL
    // Try to default to an active job the user is the hiring manager of
    const firstOwnedJob = allJobs.find(job => job.active && job.hiringManager?.id === authedProUser.id);
    if (firstOwnedJob) {
      setJobId(firstOwnedJob.id);
      return;
    }

    // If user is not HM of any jobs default to first one
    const newJobId = allJobs[0].id;
    if (newJobId) {
      setJobId(newJobId.toString());
    }
    return undefined;
  }, [allJobs, authedProUser, jobIdFromUrl, setJobId]);

  const { currentData: inboundSearches, isFetching: isInboundSearchesLoading } = useListSearchesV3Query(
    jobId ? { job: jobId, searchTypeList: SearchV3SearchTypeEnum.Inbound } : skipToken
  );

  const search = React.useMemo(() => {
    if (!inboundSearches) {
      return undefined;
    }

    const activeInboundSearches = inboundSearches.filter(search => search.active);
    return activeInboundSearches?.length ? activeInboundSearches[0] : inboundSearches[0];
  }, [inboundSearches]);

  const searchV3FormMethods = useForm<SearchV3FormSchemaType>({
    defaultValues: searchV3FormDefaultValues,
    resolver: zodResolver(searchV3FormSchema),
  });

  // This resets our page when we are switching between jobs and navigating around the app
  useReset();

  useEffect(() => {
    setSearchId(search?.id);
  }, [search, setSearchId]);

  // If no jobs
  if (allJobs?.length === 0 && !isJobsLoading) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center" direction="row" spacing={1}>
        <MagnifyingGlassSVG className="svg-fill" color={colors.grayscale.gray500} />
        <Heading color={colors.grayscale.gray500}>
          <Box
            sx={{
              cursor: "pointer",
              color: colors.link,
              display: "inline",
            }}
            onClick={(): void => {
              setCreateJobDrawerOpen(true);
            }}
          >
            Add a job
          </Box>{" "}
          to start using App Review
        </Heading>
      </Stack>
    );
  }

  // no searches v3s - must check for jobId existence because if !jobId --> we are still loading jobs
  // this means we won't have inboundSearches because we haven't run the query for them yet
  if (jobId && !inboundSearches?.length && !isInboundSearchesLoading) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center" direction="row" spacing={1}>
        <MagnifyingGlassSVG className="svg-fill" color={colors.grayscale.gray500} />
        <Heading color={colors.grayscale.gray500}>
          No searches found. Please contact your Dover team to help you resolve this.
        </Heading>
      </Stack>
    );
  }

  // Loading
  if (!jobId || !search) {
    return (
      <Box display="flex" height="100%" alignItems="center" justifyContent="center">
        <PageHelmet title={job?.title ? `${job.title} - Application Review` : "Application Review"} />
        <DoverLoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      <PageHelmet title={job?.title ? `${job.title} - Application Review` : "Application Review"} />
      <FormProvider {...searchV3FormMethods}>
        <FormLoadStateWrapper>
          <ApplicationReview jobId={jobId} search={search} />
        </FormLoadStateWrapper>
      </FormProvider>
    </>
  );
};

interface InboundAppReviewV3Props {
  jobId?: string;
  search: SearchV3;
}

const ApplicationReview = ({ jobId, search }: InboundAppReviewV3Props): ReactElement => {
  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Set up the SaaP Form State
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  // Load in initial form values
  // We do this here because sometimes we use these params in the ai chat panel without ever opening the saap panel
  const formLoadState = useContext(FormLoadStateContext);
  const setInitialFormValuesLoaded = formLoadState?.setFormValuesLoaded;
  const initialFormValuesLoaded = formLoadState?.loaded;
  const setFormValuesLoading = formLoadState?.setFormValuesLoading;
  const formValuesLoading = formLoadState?.formValuesLoading;

  useLoadFormValues(!!initialFormValuesLoaded, !!formValuesLoading, setFormValuesLoading, setInitialFormValuesLoaded);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      URL Params
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const [selectedApplicationIdParam, setSelectedApplicationIdParam] = useQueryParam(
    applicationIdQueryParam,
    StringParam
  );

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Local State
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const [debouncedSearchParams, setSearchParams] = useDebounceState<SearchV3Params | undefined>(undefined, 500);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Global State
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const [criteriaMode, setCriteriaMode] = useAtom(criteriaModeAtom);
  const [isMainPanelOpenMobile, setIsMainPanelOpenMobile] = useAtom(isMainPanelOpenMobileAtom);
  const setSaapParams = useSetAtom(saapParamsAtom);
  const setToNextApplication = useSetAtom(toNextApplicationAtom);
  const incrementNumActioned = useSetAtom(incrementNumActionedCandidatesAtom);

  const {
    applications,
    isLoading: isLoadingApplications,
    isFetching: isFetchingApplications,
    isError: errorFetchingApps,
  } = useApplications();

  const numApplicants = useSelectedTabCount();

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Derived State
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();
  const selectedApplication = applications?.find(
    application => application?.inboundAppId === selectedApplicationIdParam
  );

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      SaaP Form State
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const { control } = useFormContext<SearchV3FormSchemaType>();
  const values = useWatch({ control });
  const formParseResult = React.useMemo(() => searchV3FormSchema.safeParse(values), [values]);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Hotkeys
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const toPrevApplication = useCallback(() => {
    if (!selectedApplication || !applications) {
      return;
    }

    const currApplicationIndex = findApplicationIndex(applications, selectedApplication.inboundAppId);

    // Couldn't find the application
    if (currApplicationIndex === undefined) {
      return;
    }

    const prevApplicationIndex = currApplicationIndex - 1;

    // Early return if at beginning of list already
    if (prevApplicationIndex < 0) {
      return;
    }

    // Set the new selected application id in the url
    setSelectedApplicationIdParam(applications[prevApplicationIndex].inboundAppId);
  }, [applications, selectedApplication, setSelectedApplicationIdParam]);

  const toNextApplication = useCallback(() => {
    if (!selectedApplication || !applications) {
      return;
    }

    // any time we action a candidate, this callback will be called
    // even if there are no more candidates to action
    incrementNumActioned();

    const currApplicationIndex = findApplicationIndex(applications, selectedApplication.inboundAppId);

    // Couldn't find the application
    if (currApplicationIndex === undefined) {
      return;
    }

    const nextApplicationIndex = currApplicationIndex + 1;

    // Early return if at end of list already
    if (nextApplicationIndex >= applications.length) {
      return;
    }

    // Set the new selected application id in the url
    setSelectedApplicationIdParam(applications[nextApplicationIndex].inboundAppId);
  }, [applications, selectedApplication, setSelectedApplicationIdParam, incrementNumActioned]);

  useHotkey(PREV_APPLICATION, "Go to previous application", toPrevApplication);
  useHotkey(NEXT_APPLICATION, "Go to next application", toNextApplication);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Effects
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  // Pop open the side panel on initial load for jobs with scoring disabled
  useEffect(() => {
    if (!jobHasScoringEnabled) {
      setCriteriaMode(CriteriaMode.Criteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Debounced update on the search params when the form values change
  // We end up putting this into jotai in another useEffect below to be used for optimistic updates
  // Arguably this should be refactored to have this effect in the saap form directly
  // Or to connect that form state directly to jotai
  useEffect(() => {
    // Early return if a search is undefined or loading
    if (search === undefined) {
      return;
    }

    if (!initialFormValuesLoaded) {
      return;
    }

    // Early return if the search isn't parsable
    if (!formParseResult.success) {
      return;
    }

    // After updates, we'll get a referentially different search object from RTKQ
    // It's important the we check deep equality against the new search params so we don't perform an additional unnecessary update
    const newSearchParams = getSearchV3FromFormState(formParseResult.data, search).v3Params;
    if (isEqual(search.v3Params, newSearchParams) && !isEqual(debouncedSearchParams, newSearchParams)) {
      setSearchParams(search.v3Params);
      return;
    }

    // Prevent unnecessary rerenders
    if (!isEqual(debouncedSearchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [debouncedSearchParams, formParseResult, initialFormValuesLoaded, search, setSearchParams, values]);

  useEffect(() => {
    setSaapParams(debouncedSearchParams);
  }, [debouncedSearchParams, setSaapParams]);

  // We put this in jotai to use for optimistic updates
  useEffect(() => {
    // Wrapping in an anonymous function because jotai setters accept functions as inputs and calls them
    // So if you pass toNextApplication directly, it will get called immediately
    setToNextApplication(() => toNextApplication);
  }, [setToNextApplication, toNextApplication]);

  // Default to the first application if the selected application (comes from the url) isn't in the list
  useEffect(() => {
    // Early return if we are still loading or don't have any applications
    if (isLoadingApplications || !applications || !applications.length) {
      return;
    }

    // Selected application does exist in the list, so early return
    if (selectedApplication) {
      return;
    }

    // Couldn't find the selected application so default to the first one
    setSelectedApplicationIdParam(applications[0].inboundAppId);
  }, [applications, setSelectedApplicationIdParam, isLoadingApplications, selectedApplication]);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Click Handlers
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const clickAllApplicants = (): void => {
    setIsMainPanelOpenMobile(false);
  };

  return (
    <Stack direction="row" spacing={1} height="100%" px="1rem" py="0.5rem">
      {/* Criteria Panel / Chat Panel */}
      {criteriaMode && (
        <Stack
          minWidth={FILTERS_WIDTH} // The saap criteria form is shared with the sourcing page, so we need to share this min width so that both pages look good
          maxWidth={{ xs: "none", md: "350px" }}
          flex={1}
          bgcolor={colors.grayscale.gray100}
          border={`1px solid ${colors.grayscale.gray200}`}
          borderRadius="6px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          overflow="hidden" // This is to stop the chat background from clipping the bottom corners of the border
        >
          <CriteriaPanel jobId={jobId!} search={search} />
        </Stack>
      )}
      {/* Application List Panel */}
      <Stack
        minWidth="325px"
        maxWidth={{ xs: "none", md: "400px" }}
        display={{ xs: criteriaMode || isMainPanelOpenMobile ? "none" : "flex", md: "flex" }}
        flex={1}
        bgcolor={colors.white}
        border={`1px solid ${colors.grayscale.gray200}`}
        borderRadius="6px"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden" // This is to stop the multi select action bar from clipping the bottom corners of the border
      >
        <ApplicantListPanel
          jobId={jobId}
          selectedApplication={selectedApplication}
          setSelectedApplicationId={setSelectedApplicationIdParam}
          isFetchingApplications={isFetchingApplications}
          numApplicants={numApplicants}
          paramsAreBroken={!formParseResult.success}
        />
      </Stack>
      {/* Main panel */}
      <Stack height="100%" display={{ xs: isMainPanelOpenMobile ? "flex" : "none", md: "flex" }} flex={3}>
        <Box display={{ xs: "flex", md: "none" }} width="min-content">
          <Button onPress={clickAllApplicants}>{`< All Applicants`}</Button>
        </Box>
        <Stack
          minHeight={0}
          height="100%"
          border={`1px solid ${colors.grayscale.gray200}`}
          borderRadius="6px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          sx={{
            backgroundImage: `linear-gradient(${colors.grayscale.gray100}, ${colors.white} 10%)`,
          }}
        >
          <MainPanel
            application={selectedApplication}
            noResults={!applications?.length}
            errorFetchingApps={errorFetchingApps}
            isFetching={isFetchingApplications}
            paramsAreBroken={!formParseResult.success}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApplicationReviewMainNew;
