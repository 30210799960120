import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import { useForm, useFormState } from "react-final-form";

import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodyExtraSmall } from "components/library/typography";
import { InterviewerSchedulingInfoSchedulingLinkConfigEnum } from "services/openapi";
import { colors } from "styles/theme";
import { InterviewPreferencesFormValues } from "views/CompanySetup/components/InterviewPreferences/types";

const SCHEDULING_LINK_CONFIG_LABELS_AND_DESCRIPTIONS: Readonly<
  {
    [key in InterviewerSchedulingInfoSchedulingLinkConfigEnum]: {
      label: string;
      description: string;
    };
  }
> = {
  [InterviewerSchedulingInfoSchedulingLinkConfigEnum.DoverSchedulingLink]: {
    label: "Dover Scheduling Link [Recommended]",
    description: "Get personalized scheduling links for each candidate and log all interviews in Dover.",
  },
  [InterviewerSchedulingInfoSchedulingLinkConfigEnum.NoSchedulingLink]: {
    label: "Do not use a scheduling link",
    description: "You or your team will be responsible for manually scheduling candidate interviews.",
  },
  [InterviewerSchedulingInfoSchedulingLinkConfigEnum.ThirdPartySchedulingLink]: {
    label: "Third-party link (Calendly, etc.)",
    description: "Dover will not be able to automatically track when candidates schedule interviews.",
  },
};

export const SchedulingLinkConfigPicker = ({
  setIsDirty,
}: {
  setIsDirty: (value: boolean) => void;
}): React.ReactElement => {
  const form = useForm();
  const { values: formValues } = useFormState<InterviewPreferencesFormValues>();

  const [
    schedulingLinkConfig,
    setSchedulingLinkConfig,
  ] = useState<InterviewerSchedulingInfoSchedulingLinkConfigEnum | null>(formValues.schedulingLinkConfig);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSchedulingLinkConfigChange = (option: InterviewerSchedulingInfoSchedulingLinkConfigEnum): void => {
    form.change("schedulingLinkConfig", option);
    setIsDirty(true);
  };

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Stack spacing={1} mb={3}>
      <Body weight="500">How would you like candidates to schedule with you?</Body>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={(e: React.MouseEvent<HTMLElement>): void => {
          e.preventDefault();
          handleClick(e);
        }}
        width="600px"
      >
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Body>
            {schedulingLinkConfig
              ? SCHEDULING_LINK_CONFIG_LABELS_AND_DESCRIPTIONS[schedulingLinkConfig].label
              : "Select option"}
          </Body>
          <KeyboardArrowDownIcon />
        </Stack>
      </Button>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
        {Object.values(InterviewerSchedulingInfoSchedulingLinkConfigEnum).map(option => {
          const { label, description } = SCHEDULING_LINK_CONFIG_LABELS_AND_DESCRIPTIONS[option];

          return (
            <MenuItem
              key={option}
              onClick={(): void => {
                setSchedulingLinkConfig(option);
                handleSchedulingLinkConfigChange(option);
                handleClose();
              }}
              selected={option === schedulingLinkConfig}
            >
              <Stack spacing={0.5}>
                <Body>{label}</Body>
                <BodyExtraSmall color={colors.grayscale.gray500}>{description}</BodyExtraSmall>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
