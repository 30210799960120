import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { FC, useState } from "react";

import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { useGetUsersClientQuery, usePartialUpdateClientMutation } from "services/doverapi/endpoints/client/endpoints";
import { colors } from "styles/theme";
import { EmailSetup } from "views/EditCareersPage/components/CareersPageSetupModal/EmailSetup";
import { EmbedSetup } from "views/EditCareersPage/components/CareersPageSetupModal/EmbedSetup";
import { LinkSetup } from "views/EditCareersPage/components/CareersPageSetupModal/LinkSetup";

enum SetupType {
  Link = "link",
  Embed = "embed",
  Email = "email",
}

export const CareersPageSetupModal: FC<GlobalModalProps> = ({ isOpen, close }) => {
  const [setupType, setSetupType] = useState<SetupType | null>(SetupType.Link);

  const { data: client } = useGetUsersClientQuery();
  const [updateClientMutation, { isLoading }] = usePartialUpdateClientMutation();
  const [initialSetupState] = useState(client?.careersPagePosted);
  const [setupComplete, setSetupComplete] = React.useState(client?.careersPagePosted ?? false);

  const onChange = (_: React.MouseEvent<HTMLElement>, newSetupType: SetupType | null): void => {
    // only update if a new setup type is selected
    if (newSetupType) {
      setSetupType(newSetupType);
    }
  };

  const markComplete = async (): Promise<void> => {
    if (!client?.id) return;
    const res = await updateClientMutation({
      id: client.id,
      updatedClient: {
        careersPagePosted: true,
      },
    }).unwrap();

    setSetupComplete(res.careersPagePosted);
  };

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md">
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            "&.MuiIconButton-root": {
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grayscale.gray500,
              zIndex: 1000,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        {"Set up the Careers Page on your website"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} width="590px">
          {!initialSetupState && (
            <Banner
              variant={BannerVariant.Gray}
              noIcon={!setupComplete}
              overrideIcon={setupComplete ? <CheckIcon /> : undefined}
            >
              <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
                <BodySmall>{setupComplete ? "You are all set" : "Already linked or embedded?"}</BodySmall>
                {!setupComplete && (
                  <Button
                    variant={ButtonVariant.GreenLink}
                    onClick={markComplete}
                    removePadding
                    removeOutline
                    loading={isLoading}
                  >
                    Yes, it&apos;s on our website!
                  </Button>
                )}
              </Stack>
            </Banner>
          )}

          <ToggleButtonGroup value={setupType} onChange={onChange} exclusive>
            <ToggleButton
              value={SetupType.Link}
              sx={{
                textTransform: "none",
                textAlign: "left",
                flex: 1,
                borderRadius: "4px !important",
                ":focus": {
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            >
              <Stack spacing={1} alignItems="start">
                <LinkIcon height="28px" width="28px" />
                <Body>Get a link</Body>
                <BodySmall>{`To add to your website's navigation`}</BodySmall>
              </Stack>
            </ToggleButton>
            <ToggleButton
              value={SetupType.Embed}
              sx={{
                textTransform: "none",
                textAlign: "left",
                flex: 1,
                marginLeft: "8px !important",
                borderLeft: `1px ${colors.grayscale.gray300} solid !important`,
                borderRadius: "4px !important",
                ":focus": {
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            >
              <Stack spacing={1}>
                <CodeIcon height="28px" width="28px" />
                <Body ellipsis>Get an embed code</Body>
                <BodySmall>{`To directly embed in your website`}</BodySmall>
              </Stack>
            </ToggleButton>
            <ToggleButton
              value={SetupType.Email}
              sx={{
                textTransform: "none",
                textAlign: "left",
                flex: 1,
                marginLeft: "8px !important",
                borderLeft: `1px ${colors.grayscale.gray300} solid !important`,
                borderRadius: "4px !important",
                ":focus": {
                  outline: "none",
                  boxShadow: "none",
                },
              }}
            >
              <Stack spacing={1}>
                <MailIcon height="28px" width="28px" />
                <Body>Email instructions</Body>
                <BodySmall>{`Send setup instructions to your website admin`}</BodySmall>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
          <Box p={2} border={`1px ${colors.grayscale.gray200} solid`} borderRadius="6px">
            {setupType === SetupType.Link && <LinkSetup />}
            {setupType === SetupType.Embed && <EmbedSetup />}
            {setupType === SetupType.Email && <EmailSetup />}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const careersPageSetupModalAtom = modalAtom(CareersPageSetupModal);
