import { Stack } from "@mui/material";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";

export const InterviewerPreferencesWarningBanner: React.FC = () => (
  <Banner variant={BannerVariant.Warning}>
    <Stack spacing={1}>
      <BodySmall>
        <a href={APP_ROUTE_PATHS.userSettings.interviewPreferences()} rel="noopener noreferrer" target="_blank">
          Interview preferences
        </a>{" "}
        must be completed to insert a scheduling link.
      </BodySmall>
    </Stack>
  </Banner>
);
