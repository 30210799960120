import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "react-use";
import { v4 as uuidv4 } from "uuid";

import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { EMPTY_EMAIL_USER } from "components/dover/top-level-modal-manager/constants";
import { SchedulableStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { useInitialEmailSender } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/utils";
import { doesSenderNeedAuth } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption, EmailUser } from "components/library/TipTap/types";
import { doverApi } from "services/doverapi/apiSlice";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import {
  useGetEmailTemplateV2Query,
  useValidateSchedulingLinkForCandidateQuery,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useListClientEmailTemplatesQuery } from "services/doverapi/endpoints/client/endpoints";
import { useListContactEmailsQuery } from "services/doverapi/endpoints/contact";
import { useGetHiringPipelineStageQuery } from "services/doverapi/endpoints/hiringPipelineStage";
import { useListInterviewersQuery } from "services/doverapi/endpoints/interviewer";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { PRO_USER } from "services/doverapi/endpointTagsConstants";
import {
  CandidateBioCompletedInterview,
  EmailEventField,
  GetEmailTemplateRequestV2,
  GetEmailTemplateRequestV2DecisionEnum,
  InterviewPanel,
} from "services/openapi";

const findatimeRegex = /https:\/\/findatime.io\/[\w-/]*/; // Matches findatime scheduling links
const calendlyRegex = /https:\/\/calendly.com\/[\w-/]*/; // Matches calendly scheduling links

function matchEmailEventId(
  sender: EmailUser,
  emailEvent: EmailEventField | null | undefined
): string | null | undefined {
  if (!emailEvent || !emailEvent.emailParticipants) {
    return null;
  }

  // Return emailEvent scoped to the sender, if it exists
  let participant;
  for (participant of emailEvent.emailParticipants) {
    if (participant.emailSenderAlias?.id === sender.id) {
      return participant.emailEventId;
    }
  }
  // Otherwise, return any listed emailEventId
  return participant?.emailEventId;
}

interface UseEmailStateArgs {
  candidateId: string;
  clientEmailTemplateId?: string | null;
  decision?: GetEmailTemplateRequestV2DecisionEnum;
  stage?: SchedulableStage;
  interviewPanel?: InterviewPanel; // Used for scheduling template
  emailEventDetails?: EmailEventField | null; // Used for reply templates
  skipLinkValidation?: boolean;
  ignoreInterviewPanelBlock?: boolean;
}

export const useEmailState = ({
  candidateId,
  decision,
  stage,
  interviewPanel,
  emailEventDetails,
  clientEmailTemplateId,
  skipLinkValidation,
  ignoreInterviewPanelBlock,
}: UseEmailStateArgs): {
  to: BasicEmailOption;
  setRecipient: React.Dispatch<React.SetStateAction<BasicEmailOption | null>>;
  recipientOptions: BasicEmailOption[];
  from: EmailUser;
  setFrom: React.Dispatch<React.SetStateAction<EmailUser>>;
  senderNeedsAuth: boolean;
  initialSubject: string;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  initialBody: string;
  setInitialBody: React.Dispatch<React.SetStateAction<string>>;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  cc: BasicEmailOption[];
  setCc: React.Dispatch<React.SetStateAction<BasicEmailOption[]>>;
  bcc: BasicEmailOption[];
  setBcc: React.Dispatch<React.SetStateAction<BasicEmailOption[]>>;
  messageKey: string;
  threadId?: string | null;
  isFetching: boolean;
  isValidatingLink: boolean;
  invalidLink: boolean;
  hasFindatimeLink: boolean;
  hasCalendlyLink: boolean;
  completedInterviews?: CandidateBioCompletedInterview[];
  initialClientEmailTemplateId?: string;
} => {
  const dispatch = useDispatch();

  /*
    Local Stage
  */
  const [from, setFrom] = useState<EmailUser>(EMPTY_EMAIL_USER);
  const fromNeedsAuth = from.id === "" ? false : doesSenderNeedAuth(from);

  const [subject, setSubject] = useState<string>("");
  const [initialSubject, setInitialSubject] = useState<string>("");
  // Body and subject is a little weird because the tip tap editor is actually semi uncontrolled.
  // We just want to set the initial state for body and let TipTap handle the rest.
  // We track the changes with our own local "body" state, but we don't feed that value to
  // TipTap. Because everytime you change the body passed to tiptap it completely resets
  // all editor state and reinitizlizes, which has undesired behavior like reseting cursor position.
  const [body, setBody] = useState<string>("");
  const [initialBody, setInitialBody] = useState<string>("");
  const [cc, setCc] = useState<BasicEmailOption[]>([]);
  const [bcc, setBcc] = useState<BasicEmailOption[]>([]);
  const [recipientOptions, setRecipientOptions] = useState<BasicEmailOption[]>([]);
  const [recipient, setRecipient] = useState<BasicEmailOption | null>(null);

  /*
    Email auth check state
  */
  // When do we need to for the current user to auth before showing them the email editor?
  //  - the sender is the current user
  //  - they haven't authed their gmail
  const { data: authedProUser } = useGetAuthedUserInfoQuery();
  const senderIsCurrentUser = from.email === authedProUser?.email;
  const skipAuthedSuccessfullyCheck = !senderIsCurrentUser || !fromNeedsAuth;
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully(skipAuthedSuccessfullyCheck);
  const senderNeedsAuth = senderIsCurrentUser && userAuthedSuccessfully !== undefined && !userAuthedSuccessfully;

  /*
    Validate findatime links
  */
  // We need to check if the body contains a findatime link
  // and if it does validate the link in the backend
  // However we don't want to spam api calls, so we debounce the body
  // and use that for the api call
  const [debouncedBody, setDebouncedBody] = useState("");
  const [getDebounceStatus] = useDebounce(
    () => {
      setDebouncedBody(body);
    },
    500,
    [body]
  );

  // Find the first findatime link in the body and send it to the backend
  // This solution currently only checks the first link, so any additional links are not validated
  const findatimeMatches = debouncedBody.match(findatimeRegex);
  const hasFindatimeLink = Boolean(findatimeMatches?.[0]);
  const validateArgs =
    !skipLinkValidation && findatimeMatches?.[0] ? { data: { candidateId, link: findatimeMatches[0] } } : skipToken;
  const { data: isValidLink, isFetching: isValidateLinkFetching } = useValidateSchedulingLinkForCandidateQuery(
    validateArgs
  );

  // Check if a calendly link is in the body
  const hasCalendlyLink = Boolean(debouncedBody.match(calendlyRegex)?.[0]);

  /*
    API Calls
  */
  const { data: bio, isFetching: isBioFetching, isUninitialized: isBioUninitialized } = useGetCandidateBioQuery(
    candidateId
  );

  // Loading states
  const { isFetching: isFetchingInterviewers } = useListInterviewersQuery();
  const { isFetching: isFetchingClientEmailTemplates } = useListClientEmailTemplatesQuery({});
  const { isFetching: isFetchingHiringPipelineStage } = useGetHiringPipelineStageQuery(
    bio?.job && stage?.id
      ? {
          jobId: bio?.job,
          hpsId: stage?.id!,
        }
      : skipToken
  );

  // we should be using the template related to the currently selected stage in the scheduling modal
  // because we can approve a candidate for arbitrary stages, won't necessarily come from next action.
  // as such, we should have a default set inside the SchedulingModal for approve context
  const initialClientEmailTemplateId = useMemo(
    () =>
      decision === GetEmailTemplateRequestV2DecisionEnum.Reject
        ? bio?.nextAction?.hiringPipelineStage?.rejectionEmailTemplateId
        : undefined,

    [bio?.nextAction, decision]
  );

  function getQueryArgs(): typeof skipToken | GetEmailTemplateRequestV2 {
    if (!from?.id) {
      return skipToken;
    }

    switch (decision) {
      case GetEmailTemplateRequestV2DecisionEnum.Approve:
        // With the improved scheduling UX, we want to fetch the scheduling template even if we won't be able to subst the interviewer variables.
        if (!ignoreInterviewPanelBlock) {
          // Block on interview panel
          if (!interviewPanel) {
            return skipToken;
          }
        }
        break;
      case GetEmailTemplateRequestV2DecisionEnum.Reschedule:
        if (!stage?.id) {
          return skipToken;
        }
        break;
      // We'll infer stage on the backend for rejection
      case GetEmailTemplateRequestV2DecisionEnum.Reject:
        break;
      case GetEmailTemplateRequestV2DecisionEnum.Email:
        break;
    }

    return {
      candidateId,
      emailAliasId: from.id,
      // need to force the backend to use the blank template handler for threading context from reply button
      decision:
        decision === GetEmailTemplateRequestV2DecisionEnum.Email && !clientEmailTemplateId ? undefined : decision,
      previousEmailEventId: matchEmailEventId(from, emailEventDetails),
      desiredHiringPipelineStageId: stage?.isOneOffInterview ? undefined : stage?.id,
      interviewPanel,
      clientEmailTemplateId: clientEmailTemplateId === undefined ? initialClientEmailTemplateId : clientEmailTemplateId,
    };
  }
  const args = getQueryArgs();
  const {
    currentData: data,
    isFetching: isTemplateFetching,
    isUninitialized: isTemplateUninitialized,
    isError,
  } = useGetEmailTemplateV2Query(args);
  const { data: contactEmailsResult } = useListContactEmailsQuery(
    bio?.contact?.id ? { contactId: bio?.contact?.id! } : skipToken
  );

  const fullName = bio?.contact?.fullName;
  const defaultTo: BasicEmailOption = { id: candidateId, email: data?.toEmails?.[0] ?? "", label: fullName };

  /*
    Effects
  */
  // Set the initial email sender once the candidate bio loads
  const initialEmailSender = useInitialEmailSender({ candidateId });
  useEffect(() => {
    if (from.id === "" && from.email === "" && initialEmailSender) {
      setFrom(initialEmailSender);
    }
  }, [from, initialEmailSender, setFrom]);

  // Set initial email state once the template loads
  // Also overwrite anytime the data changes (e.g. on stage change)
  useEffect(() => {
    if (data) {
      // Convert to BasicEmailOption for editor
      const cc: BasicEmailOption[] = data.ccEmails?.map(e => ({ id: uuidv4(), email: e })) ?? [];
      const bcc: BasicEmailOption[] = data.bccEmails?.map(e => ({ id: uuidv4(), email: e })) ?? [];

      setSubject(data.subject);
      setInitialSubject(data.subject);
      setBody(data.body);
      setInitialBody(data.body);
      setCc(cc);
      setBcc(bcc);
    }
  }, [data, fullName]);

  useEffect(() => {
    if (contactEmailsResult && fullName) {
      const emails = contactEmailsResult?.results || [];
      const recipientEmails = emails?.map(e => ({ id: uuidv4(), email: e.email, label: fullName })) ?? [];
      setRecipientOptions(recipientEmails);
    }
  }, [contactEmailsResult, fullName]);

  /*
    Email auth check finish logic
  */
  //
  // Due to how we fetch the from sender (through a chain of nested hooks each composed of multiple api calls and conditional logic).
  // This is the easiest method to update the from sender auth validity which in turn drives validation logic in the email editor.
  useEffect(() => {
    // We use the established hook for check if the current user has authed their gmail
    // This is the userAuthedSuccessfully state
    // fromNeedAuth is derived from the "from" state which comes through the ^ hooks mentioned above
    // When they get out of sync after a user auths their gmail, apply the following manual update
    // This isn't ideal but it's much easier and less error prone than trying to update the state in the hook chain
    // Long term a consolidating of source of truth / a simplification of sender derivation would be ideal
    if (fromNeedsAuth && senderIsCurrentUser && userAuthedSuccessfully) {
      // Invalidate so a modal reopen will display things as expected
      // It's unfortunate this invalidation doesn't cause the from sender to update in the hook cluster fuck chain
      dispatch(doverApi.util.invalidateTags([{ type: PRO_USER, id: authedProUser?.id }]));

      // Set to valid here and now in the email editor
      setFrom({
        ...from,
        isAuthed: true,
      });
    }
  }, [
    fromNeedsAuth,
    senderIsCurrentUser,
    userAuthedSuccessfully,
    candidateId,
    dispatch,
    authedProUser?.id,
    setFrom,
    from,
  ]);

  return {
    to: recipient ?? defaultTo,
    setRecipient,
    recipientOptions,
    from,
    setFrom,
    senderNeedsAuth,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    setInitialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey: data?.messageKey ?? "",
    threadId: data?.threadId,
    isFetching:
      !isError &&
      !!decision &&
      (isBioUninitialized ||
        isFetchingInterviewers ||
        isFetchingClientEmailTemplates ||
        isFetchingHiringPipelineStage ||
        (args !== skipToken && isTemplateUninitialized) ||
        isTemplateFetching ||
        isBioFetching),
    isValidatingLink: isValidateLinkFetching || !getDebounceStatus(), // We should disable sending while the validate api is loading or the body is still debounced and hasn't had a chance to validate yet
    invalidLink: validateArgs !== skipToken && (isValidLink ? !isValidLink.valid : false), // Just return false while it's loading, we should only show the banner when we KNOW it's an invalid link
    hasFindatimeLink,
    hasCalendlyLink,
    completedInterviews: bio?.completedInterviews,
    initialClientEmailTemplateId,
  };
};
